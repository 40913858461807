import { 
	Component, 
	OnInit
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router'

import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { TestService } from '../services/test.service';
import { UserService } from '../services/user.service';

@Component({
	selector: 'sign-up',
	templateUrl: './sign-up.component.html',
	styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

	private fname_control = new FormControl('', [Validators.required]);
	private lname_control = new FormControl('', [Validators.required]);
	private email_control = new FormControl('', [Validators.required, Validators.email]);
	private password1_control = new FormControl('', [Validators.required, Validators.minLength(8)]);
	private passwordMatchValidator(): ValidatorFn {
		return (password2_control: FormControl) => {
			if (this.password1_control.value == password2_control.value) {
				return null;
			} else {
				return {matches: {dummy: true}};
			}
		}
	}
	private password2_control = new FormControl('', [Validators.required, Validators.minLength(8), this.passwordMatchValidator()]);

	private signUpForm = new FormGroup({
		fname: this.fname_control, 
		lname: this.lname_control, 
		email: this.email_control, 
		password1: this.password1_control, 
		password2: this.password2_control
	});

	private hide_password = true;

	private team_invitation_key: string;

	private waitingForServerResponse = false;

	constructor(
		private route: ActivatedRoute, 
		private router: Router, 
		private testService: TestService, 
		private userService: UserService, 
	) {
		// if (this.userService.getLoggedInUser() != null) {
		// 	this.router.navigate(["/dashboard"]);
		// 	return;
		// }
	}

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			this.team_invitation_key = params.team_invitation_key;
		});
	}

	private getErrorMessage_fname(): string {
		return this.fname_control.hasError('required') ? 'You must enter a value' : '';
	}
	private getErrorMessage_lname(): string {
		return this.lname_control.hasError('required') ? 'You must enter a value' : '';
	}
	private getErrorMessage_email(): string {
		return this.email_control.hasError('required') ? 'You must enter a value' : this.email_control.hasError('email') ? 'Not a valid email' : '';
	}
	private getErrorMessage_password1(): string {
		return this.password1_control.hasError('required') ? 'You must enter a value' : this.password1_control.hasError('minlength') ? 'Must be at least 8 characters' : '';
	}
	private getErrorMessage_password2(): string {
		return this.password2_control.hasError('required') ? 'You must enter a value' : this.password2_control.hasError('minlength') ? 'Must be at least 8 characters' : this.password2_control.hasError('matches') ? "Passwords do not match!" : '';
	}

	private submitSignUpForm(): void {
		this.waitingForServerResponse = true;
		this.userService.createUser(this.fname_control.value, this.lname_control.value, this.email_control.value, this.password1_control.value, this.testService.getPsychModel(), this.team_invitation_key).subscribe(
			user => {
				if (user != null) {
					// this.userService.setLoggedInUser(user);
					// this.router.navigate(["/dashboard"]);
					this.router.navigate(["/log-in"]);
				}
				this.waitingForServerResponse = false;
			}, 
			error => {
				this.waitingForServerResponse = false;
			}
		);
	}

}


