import { 
	Component, 
	ElementRef, 
	EventEmitter, 
	HostListener, 
	Input, 
	OnInit, 
	Output, 
	ViewChild, 
} from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router"

import * as d3 from "d3";

import { AppSettings } from "../app-settings";
import { TreeUtils } from "../utils/TreeUtils";

import { AppComponentService } from "../services/appcomponent.service";
import { ModuleService } from "../services/module.service";
import { UserService } from "../services/user.service";

import { RocketComponent } from "../rocket/rocket.component";

import { Course } from "../models/course";
import { Rating } from "../models/rating";
import { TeamMembership } from "../models/team-membership";

@Component({
	selector: "tree-scorecard",
	templateUrl: "./tree-scorecard.component.html",
	styleUrls: ["./tree-scorecard.component.css"]
})
export class TreeScorecardComponent implements OnInit {

	@Input() course: Course;
	@Input() activeMembership: TeamMembership;

	@Output() exited = new EventEmitter<void>();
	@Output() finished = new EventEmitter<void>();
	@Output() rated = new EventEmitter<Rating>();

	private APP_SETTINGS = AppSettings;

	private tree_data: any = null;
	private users: Array<any> = null;

	private show_tooltip: boolean = false;
	private tooltip_text_line_0: string = null;
	private tooltip_text_line_1: string = null;
	private tooltip_text_line_2: string = null;
	private tooltip_top: string = null;
	private tooltip_left: string = null;

	private show_slider_overlay: boolean = false;
	private slider_overlay_left: string = null;
	private slider_overlay_top: string = null;
	private active_d3_datum: any;
	private active_assessment_item_name: string = null;
	private active_assessment_item_description: string = null;
	private active_assessment_item_rating: any = null;

	private active_item_text: any = null;

	private num_potential_points: number = 0;
	private num_points: number = 0;

	@ViewChild("tree_container", {static: true}) tree_container: ElementRef;
	@ViewChild("rocket", {static: true}) rocket: RocketComponent;

	constructor(
		private app_component_service: AppComponentService, 
		private route: ActivatedRoute, 
		private router: Router, 
		private module_service: ModuleService, 
		private user_service: UserService
	) {
		if (this.user_service.getLoggedInUser() == null) {
			this.router.navigate(["/"]);
			return;
		}
	}

	ngOnInit(): void {

		// console.log(this.activeMembership);

		this.route.params.subscribe(params => {
			this.module_service.get_modules_global().subscribe(
				res => {
					for (var i = 0; i < res.modules.length; i ++) {
						let module = res.modules[i];
						if (module.id == this.course.id) {
							this.tree_data = {name: res.modules[i].name, children: res.modules[i].d3_tree.children, rating: res.modules[i].d3_tree.rating};
							break;
						}
					}
					this.users = res.users;
					this.generate_tree();
				}, 
				error => {
					// Do nothing.
				}
			);
		});

	}

	private ignore_window_click: boolean = false;
	@HostListener("window:click", ["$event.target"]) 
    private on_click(target) {
    	if (this.ignore_window_click) {
    		this.ignore_window_click = false;
    		return;
    	}
    	if (typeof target.className != "string" || (target.className.indexOf("slider_button") == -1 && target.className.indexOf("slider_button_filling") == -1 && target.className.indexOf("game_mark") == -1)) {
    		if (this.active_item_text) this.active_item_text.setAttribute("fill", "black");
			this.show_slider_overlay = false;
    	}
    }

	private exit(): void {
		if (confirm("Are you sure you want to exit this scorecard?")) this.exited.emit();
	}
	private finish(): void {
		if (confirm("Are you sure you are done with this scorecard?")) this.finished.emit();
	}

	private _expand_all: any;
	private expand_all(): void {
		this._expand_all();
	}
	private _collapse_all: any;
	private collapse_all(): void {
		this._collapse_all();
	}

	private points_container_active: boolean = false;
	private on_rated(rating: number) {

		this.active_d3_datum.data.rating = rating;
		(document.getElementById("node_" + (this.active_d3_datum.id)).children[0] as any).style.fill = AppSettings.getColorForRating(rating);

		var el = this.active_d3_datum.parent;
		while (el.depth > 0) {

			var num_ratings = 0;
			var sum_ratings = 0;
			el.children.forEach(child => {
				if (child.data.rating) {
					num_ratings ++;
					sum_ratings += child.data.rating;
				}
			});
			let ave_rating = sum_ratings / num_ratings;
			el.data.rating = ave_rating;

			(document.getElementById("node_" + (el.id)).children[0] as any).style.fill = AppSettings.getColorForRating(ave_rating);

			el = el.parent;
		}

		if (rating == 10 && this.active_d3_datum.hasOwnProperty("num_potential_points") && this.active_d3_datum.num_potential_points > 0) {
			this.num_points += this.active_d3_datum.num_potential_points;

			var t = d3.transition()
				    .duration(1000)
				    .ease(d3.easeLinear);
			for (var i = 0; i < this.active_d3_datum.num_potential_points; i ++) {
				d3.select("#potential_point_" + this.active_d3_datum.id + "_" + i).style("fill", "gold");
				d3.selectAll("#potential_point_" + this.active_d3_datum.id + "_" + i).transition(t).style("opacity", 0);
			}

			let _this = this;
			setTimeout(function() {
				_this.active_d3_datum.num_potential_points = 0;
				_this.update_node(_this.active_d3_datum);

				for (var i = 0; i < _this.active_d3_datum.num_potential_points; i ++) {
					d3.select("#potential_point_" + _this.active_d3_datum.id + "_" + i).style("fill", "url(#svn_pattern)");
					d3.selectAll("#potential_point_" + _this.active_d3_datum.id + "_" + i).transition(t).style("opacity", 1);
				}
			}, 1000);
			
			this.rocket.start_animation();
			this.points_container_active = true;
			setTimeout(function() { _this.points_container_active = false; }, 1000);
		}

	}

	private on_game_item_clicked(): void {
		if (!this.active_d3_datum.hasOwnProperty("num_potential_points")) this.active_d3_datum.num_potential_points = 0;
		if (this.active_d3_datum.num_potential_points >= TreeUtils.MAX_POINTS_PER_ITEM) {
			alert("No more than 10 potential points per item!");
			return;
		}
		this.active_d3_datum.num_potential_points ++;
		this.num_potential_points ++;
		
		this.update_node(this.active_d3_datum);
	}
	private on_game_item_toggled(val: boolean): void {
		this.active_d3_datum.data.is_game_item = val;
		document.getElementById("tag_" + (this.active_d3_datum.id)).style.stroke = (val ? "gold" : "none");
	}

	private update_node: any = null;
	private generate_tree(): void {

		let _this = this;

		let root = d3.hierarchy(this.tree_data, function(d) {return d.children;});
		// Open the first level:
		root._children = root.children;
		// Collapse after the second level:
		root.children.forEach(collapse);

		let num_possible_leaves = TreeUtils.count_possible_leaves(root);
		var vert_scale = Math.round(TreeUtils.count_leaves(root) / num_possible_leaves * 100) / 100;

		let PLOT_WIDTH = TreeUtils.WIDTH;
		let PLOT_HEIGHT = TreeUtils.HEIGHT_PER_LEAF * num_possible_leaves;
		let MARGIN = {top: 0, right: 0, bottom: 0, left: 0};

		const svg = d3.create("svg").attr("viewBox", [0, 0, PLOT_WIDTH + MARGIN.left + MARGIN.right, PLOT_HEIGHT + MARGIN.top + MARGIN.bottom]);

		// Add image patterns:
		const defs = svg.append("defs");
		// for (var _ = 0; _ < this.users.length; _ ++) {
		// 	if (!this.users[_].thumbnail_filename) continue;
		// 	const pattern = defs.append("pattern")
		// 		    .attr("id", "user_" + this.users[_].id)
		// 		    .attr("height", "100%")
		// 		    .attr("width", "100%")
		// 		    .attr("viewBox", "0 0 18 18");
		// 	const image = pattern.append("image")
		// 			.attr("x", 0)
		// 			.attr("y", 0)
		// 			.attr("height", 18)
		// 			.attr("width", 18)
		// 			.attr("xlink:href", AppSettings.PHOTO_PATH + "thumbnails/" + this.users[_].thumbnail_filename);
		// }
		const svn_pattern = defs.append("pattern")
			    .attr("id", "svn_pattern")
			    .attr("height", "100%")
			    .attr("width", "100%")
			    .attr("viewBox", "0 0 7 7");
		const svn_image = svn_pattern.append("image")
				.attr("x", 0)
				.attr("y", 0)
				.attr("height", 7)
				.attr("width", 7)
				.attr("xlink:href", "/assets/SVN_logo_piece.png");

		// Add tooltips:
		const tooltip = d3.select("body")
				.append("div")
				.style("position", "absolute")
				.style("z-index", "10")
				.style("visibility", "hidden")
				.style("padding", "4px")
				.style("background", "rgba(0,0,0,0.6)")
				.style("font-size", "10px");

		svg.append("g").attr("transform", "translate("+ MARGIN.left + "," + MARGIN.top + ")");

		var node_id = 0;
		
		let treemap = d3.tree().size([PLOT_HEIGHT, PLOT_WIDTH]);

		update(root);

		function expand(d) {
			if (d._children) {
				d.children = d._children;
				d._children.forEach(expand);
				d._children = null;
			} else if (d.children) {
				d.children.forEach(expand);
				d._children = null;
			}
		}
		function collapse(d) {
			if (d.children) {
				d._children = d.children;
				d._children.forEach(collapse);
				d.children = null;
			} else if (d._children) {
				d._children.forEach(collapse);
				d.children = null;
			}
		}

		_this._expand_all = function() {
			expand(root);
			update(root);
		}
		_this._collapse_all = function() {
			root.children.forEach(collapse);
			update(root);
		}

		var ignore_tooltip = false;

		function update(source) {

			let num_leaves = TreeUtils.count_leaves(root);
			
			// Assigns the x and y position for the nodes:
			var treeData = treemap(root);

			// Compute the new tree layout:
			var nodes = treeData.descendants(), links = treeData.descendants().slice(1);

			// Normalize for fixed-depth: 
			nodes.forEach(function(d) { d.y = d.depth * 50 + 150; });

			// ****************** NODES ***************************

			var node = svg.selectAll("g.node").data(nodes, function(d) { return d.id || (d.id = ++node_id); });

			// Enter any new nodes at the parent's previous position:
			var nodeEnter = node.enter().append("g")
					.attr("id", function(d) {
						return "node_" + d.id;
					})
					.attr("class", "node")
					.attr("transform", function(d) {
						return "translate(" + (source.y0 ? source.y0 : 0) + "," + ((source.x0 ? source.x0 : 0) * vert_scale) + ")";
					})
					.on("click", on_node_click)
					.on("mouseover", function(d) {
						if (ignore_tooltip) return;
						// if (d.data.hasOwnProperty("children") && d.data.children.length > 0) return;
						_this.show_tooltip = false;
						if (d.data.hasOwnProperty("item") && d.data.item && d.data.item.hasOwnProperty("question") && d.data.item.question) {
							_this.tooltip_text_line_0 = d.data.item.question;
							_this.show_tooltip = true;
						} else {
							_this.tooltip_text_line_0 = null;
						}
						if (d.data.hasOwnProperty("item") && d.data.item && d.data.item.hasOwnProperty("description") && d.data.item.description) {
							_this.tooltip_text_line_1 = d.data.item.description;
							_this.show_tooltip = true;
						} else {
							_this.tooltip_text_line_1 = null;
						}
						if (d.data.hasOwnProperty("item") && d.data.item && d.data.item.hasOwnProperty("name") && d.data.item.name) {
							_this.tooltip_text_line_2 = d.data.item.name;
							_this.show_tooltip = true;
						} else {
							_this.tooltip_text_line_2 = null;
						}
					})
					.on("mousemove", function(d) {
						_this.tooltip_top = d3.event.pageY - document.getElementById("main_content_wrapper").offsetTop + TreeUtils.OVERLAY_VERTICAL_OFFSET + "px";
						_this.tooltip_left = d3.event.pageX - document.getElementById("main_content_wrapper").offsetLeft + TreeUtils.OVERLAY_HORIZONTAL_OFFSET + "px";
					})
					.on("mouseout", function(d) {
						_this.show_tooltip = false;
					});

			// Add node circles:
			nodeEnter.append("circle")
					.attr("class", "node")
					.attr("r", 1e-6)
					.attr("cursor", "pointer")
					.style("fill", function(d) {
						if (d.data.rating) return AppSettings.getColorForRating(d.data.rating);
						return d._children ? AppSettings.SVN_BLUE : "black";
					});
			// Add tag circles:
			nodeEnter.append("circle")
					.attr("id", function(d) {
						return "tag_" + d.id;
					})
					.attr("class", "tag")
					.attr("r", 1e-6)
					.style("stroke", function(d) {
						// return d.data.tagged ? "white" : "none";
						return d.data.is_game_item ? "gold" : "none";
					})
					.style("stroke-width", "2")
					.style("fill", "rgba(0,0,0,0)")
					.style("cursor", "pointer");

			// Add potential points:
			for (var i = 0; i < TreeUtils.MAX_POINTS_PER_ITEM; i ++) {
				nodeEnter.append("circle")
						.attr("id", function(d) {
							return "potential_point_" + d.id + "_" + i;
						})
						.attr("class", "potential_point potential_point_" + i)
						.attr("r", 1e-6)
						.attr("cx", function(d) {
							return 16 * (i + 1)
						})
						.style("stroke-width", "2")
						.style("fill", "url(#svn_pattern)");
			}

			// // Add labels:
			// nodeEnter.append("text")
			// 		.attr("dy", ".35em")
			// 		.attr("fill", "black")
			// 		.attr("stroke", "none")
			// 		.style("font-size", "8px")
			// 		.text(function(d) {
			// 			if (d.depth != 1 && ((d._children && d._children.length > 0) || (d.children && d.children.length > 0))) return "";
			// 			if (d.data.name) return d.data.name;
			// 			if (d.data.item && d.data.item.question) return d.data.item.question;
			// 			if (d.data.id_str) return d.data.id_str;
			// 			return ""; 
			// 		})
			// 		.attr("cursor", "pointer");
			// setTimeout(function() {
			// 	nodeEnter.insert("rect", "text")
			// 		    .attr("x", function(d) {
			// 		    	return (this.parentNode.getBBox().width + 5) * -1;
			// 		    })
			// 		    .attr("y", function(d) {
			// 		    	return TreeUtils.compute_text_offset_rect_y_for_datum(d);
			// 		    })
			// 		    .attr("width", function(d) {
			// 		    	return d.depth == 1 ? this.parentNode.getBBox().width : 0;
			// 		    })
			// 		    .attr("height", function(d) {
			// 		    	return d.depth == 1 ? this.parentNode.getBBox().height : 0;
			// 		    })
			// 		    .style("fill", TreeUtils.TEXT_OFFSET_RECT_FILL_COLOR);
			// }, 0);

			// // Add agent images:
			// svg.selectAll("g.node").each(function(d, i){
			//     if (d.data.ratings) {
			//       	for (var _ = 0; _ < d.data.ratings.length; _ ++) {
			//       		let __xx__ = _;
			// 			d3.select(this).append("circle")
			// 					.attr("class", "rating")
			// 					.attr("r", 9)
			// 					.style("stroke", AppSettings.getColorForRating(d.data.ratings[_].average))
			// 					.style("stroke-width", 2)
			// 					.style("fill", "url(#user_" + d.data.ratings[_].user_id + ")")
			// 					.attr("cx", _ * 23)
			// 					.attr("cy", 17)
			// 					.on("mouseover", function(_d) {
			// 						var text = "";
			// 						for (var __ = 0; __ < _this.users.length; __ ++) {
			// 							if (_this.users[__].id == _d.data.ratings[__xx__].user_id) {
			// 								text = _this.users[__].name + ": ";
			// 								break;
			// 							}
			// 						}
			// 						text += Math.round(_d.data.ratings[__xx__].average * 10) / 10;
			// 						tooltip.text(text);
			// 						return tooltip.style("visibility", "visible");
			// 					})
   //    							.on("mousemove", function() {
   //    								return tooltip.style("top", (d3.event.pageY - 10) + "px").style("left", (d3.event.pageX + 10) + "px");
   //    							})
   //    							.on("mouseout", function() {
   //    								return tooltip.style("visibility", "hidden");
   //    							});
			//       	}
			//     }  
			// });

			var nodeUpdate = nodeEnter.merge(node);

			var link = svg.selectAll("path.link").data(links, function(d) { return d.id; });

			// Enter any new links at the parent's previous position:
			var linkEnter = link.enter().insert("path", "g")
					.attr("class", "link")
					.attr("d", function(d) {
						var o = {x: source.x0, y: source.y0}
						return diagonal(o, o)
					})
					.style("fill", "none")
					.style("stroke", AppSettings.SVN_BLUE)
					.style("stroke-width", "1");

			var linkUpdate = linkEnter.merge(link);

			// Have to adjust vert_scale here to get the animation right:
			vert_scale = Math.round(num_leaves / num_possible_leaves * 100) / 100;

			// Transition to the proper position for the node:
			nodeUpdate.transition()
					.duration(TreeUtils.ANIMATION_DURATION)
					.attr("transform", function(d) { 
						return "translate(" + d.y + "," + (d.x * vert_scale) + ")";
					});

			// Update the node attributes and style:
			nodeUpdate.select("circle.node")
					.attr("r", function(d) {
						// return 5 + (5 - d.depth);
						return 5;
					})
					.style("fill", function(d) {
						if (d.data.rating) return AppSettings.getColorForRating(d.data.rating);
					    return d._children ? AppSettings.SVN_BLUE : "black";
					});
			nodeUpdate.select("circle.tag")
					.attr("r", 7);
			for (var i = 0; i < TreeUtils.MAX_POINTS_PER_ITEM; i ++) {
				nodeUpdate.select("circle.potential_point_" + i)
						.style("stroke", function(d) {
							return (!d.hasOwnProperty("num_potential_points") || d.num_potential_points <= i) ? "transparent" : "gold";
						})
						.attr("r", function(d) {
							return (!d.hasOwnProperty("num_potential_points") || d.num_potential_points <= i) ? 1e-6 : 7;
						});
			}

			// Update the labels:
			nodeUpdate.select("text")
					.attr("x", function(d) {
						return TreeUtils.compute_text_x_for_datum(d, false);
					})
					.attr("text-anchor", function(d) {
						return TreeUtils.compute_text_anchor_for_datum(d, false);
					});

			// Update the rect:
			nodeUpdate.select("rect")
					.attr("y", function(d) {
						return TreeUtils.compute_text_offset_rect_y_for_datum(d, false);
					});

			// Remove any exiting nodes:
			var nodeExit = node.exit().transition()
					.duration(TreeUtils.ANIMATION_DURATION)
					.attr("transform", function(d) {
						return "translate(" + source.y + "," + (source.x * vert_scale) + ")";
					})
					.remove();

			// On exit reduce the circles size to 0:
			nodeExit.select("circle").attr("r", 1e-6);
			nodeExit.selectAll("circle.potential_point").attr("r", 1e-6);

			// On exit reduce the opacity of text labels:
			nodeExit.select("text").style("fill-opacity", 1e-6);

			// ****************** LINKS ***************************

			// Transition back to the parent element position:
			linkUpdate.transition()
					.duration(TreeUtils.ANIMATION_DURATION)
					.attr("d", function(d) { return diagonal(d, d.parent) });

			// Remove any exiting links:
			var linkExit = link.exit().transition()
					.duration(TreeUtils.ANIMATION_DURATION)
					.attr("d", function(d) {
						var o = {x: source.x, y: source.y}
						return diagonal(o, o)
					})
					.remove();

			// Store the old positions for transition:
			nodes.forEach(function(d) {
				d.x0 = d.x;
				d.y0 = d.y;
			});

			// Creates a curved (diagonal) path from parent to the child nodes:
			function diagonal(s, d) {
				let path = `M ${s.y} ${(s.x * vert_scale)}
				        C ${(s.y + d.y) / 2} ${(s.x * vert_scale)},
				          ${(s.y + d.y) / 2} ${(d.x * vert_scale)},
				          ${d.y} ${(d.x * vert_scale)}`;
				return path;
			}

			// Toggle children on click:
			function on_node_click(d) {
				if (d.data.hasOwnProperty("item") && d.data.item && (!d.children || d.children.length == 0) && (!d._children || d._children.length == 0)) {
					_this.show_slider_overlay = true;
					_this.slider_overlay_top = d3.event.pageY - document.getElementById("main_content_wrapper").offsetTop + TreeUtils.OVERLAY_VERTICAL_OFFSET + "px";
					_this.slider_overlay_left = d3.event.pageX - document.getElementById("main_content_wrapper").offsetLeft + TreeUtils.OVERLAY_HORIZONTAL_OFFSET + "px";
					_this.active_d3_datum = d;
					if (d.data.item.hasOwnProperty("name") && d.data.item.name.length > 0) {
						_this.active_assessment_item_name = d.data.item.name;
					} else {
						_this.active_assessment_item_name = null;
					}
					if (d.data.item.hasOwnProperty("description") && d.data.item.description.length > 0) {
						_this.active_assessment_item_description = d.data.item.description;
					} else {
						_this.active_assessment_item_description = null;
					}
					_this.active_assessment_item_rating = (d.data.hasOwnProperty("rating") && d.data.rating) ? d.data.rating : -1;
					// Animation hack:
					if (_this.active_assessment_item_rating == -1) setTimeout(function() {_this.active_assessment_item_rating = -2;}, 0);

					if (_this.active_item_text) _this.active_item_text.setAttribute("fill", "black");
					let node = document.getElementById("node_" + d.id);
					if (node.children.length >= 4) {
						_this.active_item_text = document.getElementById("node_" + d.id).children[3];
						_this.active_item_text.setAttribute("fill", AppSettings.SVN_BLUE);
					} else {
						_this.active_item_text = null;
					}
					_this.ignore_window_click = true;
				} else {
					_this.show_slider_overlay = false;
				}

				if (d.children) {
					d._children = d.children;
					d.children = null;
				} else {
					d.children = d._children;
					d._children = null;
				}
				
				ignore_tooltip = true;
				setTimeout(function() { ignore_tooltip = false; }, TreeUtils.ANIMATION_DURATION);

				update(d);
			}

		}

		_this.update_node = update;

		_this.tree_container.nativeElement.append(svg.node());

	}

}


