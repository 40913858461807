
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettings } from '../app-settings';

@Injectable()
export class PromptsService {

	constructor(
		private httpClient: HttpClient, 
	) { }

	public getQueuedItems(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-queued-items", 
			{withCredentials: true}
		).pipe(
			map(res => {
				let success = (res as any).success;
				if (!success) return null;
				return res as any;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

	public getPrompts(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-prompts", 
			{withCredentials: true}
		).pipe(
			map(res => {
				let success = (res as any).success;
				if (!success) return null;
				return res as any;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

}


