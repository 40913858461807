import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettings } from '../app-settings';

import { CourseSubscription } from '../models/course-subscription';
import { User } from '../models/user';

@Injectable()
export class UserService {

	private loggedInUser: User;

	constructor(
		private httpClient: HttpClient, 
		private router: Router
	) { }

	createUserObject(src: any): User {
		if (src) {
			var user = new User(
					src.id, 
					src.fname, 
					src.lname, 
					src.email, 
					src.phone, 
					src.psych_model ? JSON.parse(src.psych_model) : null, 
					src.selected_facets, 
					src.is_coach == 1, 
					src.photo_filename, 
					src.thumbnail_filename, 
					src.context, 
					src.has_reports, 
					src.invitation_key, 
					src.timezone, 
					src.call_availability, 
					src.hasOwnProperty("team_leader") ? src.team_leader : -1, 
				);
			return user;
		} else {
			return new User(
					-1, 
					"", 
					"", 
					"", 
					"", 
					null, 
					"", 
					false, 
					null, 
					null, 
					0, 
					false, 
					"", 
					"", 
					"[]", 
					-1, 
				);
		}
	}

	setLoggedInUser(loggedInUser: User): void {
		this.loggedInUser = loggedInUser;
	}
	getLoggedInUser(): User {
		return this.loggedInUser;
	}
	loadLoggedInUser(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-logged-in-user", 
			{withCredentials: true}
		).pipe(
			map(res => {
				var user = this.extractData_loadLoggedInUser(res);
				this.setLoggedInUser(user);
				return user;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}
	private extractData_loadLoggedInUser(res: any): User {
		if (res.success) {
			return this.createUserObject(res.user);
		} else {
			// alert(res.message);
			return null;
		}
	}

	heartbeat(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "heartbeat", 
			{withCredentials: true}
		).pipe(
			map(res => {
				return null;
			}),
			catchError(error => {
				return null;
			})
		);
	}

	delete_user_video(module_id: number): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "delete-user-video", 
			{module_id: module_id}, 
			{withCredentials: true}
		).pipe(
			map(res => {
				if ((res as any).success) {
					return res;
				} else {
					return null;
				}
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

	getUserSettings(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-user-settings", 
			{withCredentials: true}
		).pipe(
			map(res => {
				if ((res as any).success) {
					return res;
				} else {
					return null;
				}
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

	setUserSettings(strategy_override: Array<number>, tenure_override: number, focus_override: Array<number>): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "set-user-settings", 
			{strategy_override: strategy_override, tenure_override: tenure_override, focus_override: focus_override}, 
			{withCredentials: true}
		).pipe(
			map(res => {
				if ((res as any).success) {
					return true;
				} else {
					return false;
				}
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
  	}

	getCourseSubscriptions(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-course-subscriptions", 
			{withCredentials: true}
		).pipe(
			map(res => {
				return this.extractData_getCourseSubscriptions(res);
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}
	private extractData_getCourseSubscriptions(res: any): Array<CourseSubscription> {
		if (res.success) {
			let subscriptions = new Array<CourseSubscription>();
			for (var i = 0; i < res.subscriptions.length; i ++) {
				subscriptions.push(new CourseSubscription(res.subscriptions[i]));
			}
			return subscriptions;
		} else {
			// alert(res.message);
			return null;
		}
	}

	createUser(fname: string, lname: string, email: string, password: string, psych_model: any, team_invitation_key: string): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "headless-create", 
			JSON.stringify({fname: fname, lname: lname, email: email, password: password, psych_model: psych_model, team_invitation_key: team_invitation_key}), 
			{headers: AppSettings.POST_HEADERS}
		).pipe(
			map(res => this.extractData_createUser(res)),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}
	private extractData_createUser(res: any) {
		if (res.success) {
			return this.createUserObject(res.user);
		} else {
			alert(res.message);
			return null;
		}
	}

	logIn(email: string, password: string): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "headless-login", 
			JSON.stringify({email: email, password: password}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				var user = this.extractData_logIn(res);
				this.setLoggedInUser(user);
				return user;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}
	private extractData_logIn(res: any): User {
		if (res.success) {
			return this.createUserObject(res.user);
		} else {
			alert(res.message);
			return null;
		}
	}

	logOut(): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "headless-logout", 
			{}, 
			{withCredentials: true}
		).pipe(
			map(res => {
				var success = this.extractData_logOut(res);
				if (success) this.setLoggedInUser(null);
				return success;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}
	private extractData_logOut(res: any): boolean {
		return res.success;
	}

	requestPasswordReset(email: string): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "headless-reset", 
			{email: email}, 
			{headers: AppSettings.POST_HEADERS}
		).pipe(
			map(res => {
				return this.extractData_requestPasswordReset(res);
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}
	private extractData_requestPasswordReset(res: any): boolean {
		return res.success;
	}

	editLoggedInUser(fname: string, lname: string, email: string, phone: string, timezone: string, call_availability: Array<any>): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "edit-logged-in-user", 
			{fname: fname, lname: lname, email: email, phone: phone, timezone: timezone, call_availability: call_availability}, 
			{withCredentials: true}
		).pipe(
			map(res => {
				var user = this.extractData_editLoggedInUser(res);
				this.setLoggedInUser(user);
				return user;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
  	}
  	private extractData_editLoggedInUser(res: any): User {
		if (res.success) {
			return this.createUserObject(res.user);
		} else {
			alert(res.message);
			return null;
		}
	}

	setPasswordForLoggedInUser(password: string): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "set-password-for-logged-in-user", 
			{password: password}, 
			{withCredentials: true}
		).pipe(
			map(res => {
				return true;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
  	}
  	private extractData_setPasswordForLoggedInUser(res: any): any {
		if (res.success) {
			return true;
		} else {
			alert(res.message);
			return null;
		}
	}

}


