export class AssessmentItem {
    id: number;
    name: string;
    description: string;
    question: string;

    // Set when loading AssessmentItemConfigs:
    is_active: boolean;
    freq: string;
    start_date: Date;

    hidden: boolean;
    display_rating_history: boolean;

  	constructor(
        id: number, 
        name: string, 
        description: string, 
        question: string, 
        hidden: boolean, 
    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.question = question;
        this.hidden = hidden;

        this.display_rating_history = false;
    }

}


