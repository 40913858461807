import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettings } from '../app-settings';

import { AssessmentItemConfig } from '../models/assessment-item-config';

@Injectable()
export class AssessmentItemConfigService {

	constructor(
		private httpClient: HttpClient, 
	) { }

	createAssessmentItemConfigObject(src: any): AssessmentItemConfig {
		if (src) {
			let c = new AssessmentItemConfig(
				src.id, 
				src.assessment_item_id, 
				src.user_id, 
				src.course_id, 
				src.is_active == 1, 
				src.freq, 
				new Date(Date.parse(src.start_date))
			);
			return c;
		} else {
			return null;
		}
	}

	saveAssessmentItemConfig(config: AssessmentItemConfig): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "save-assessment-item-config", 
			JSON.stringify({config: config}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return null;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

	getAssessmentItemConfigs(course_id: number): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-assessment-item-configs?course_id=" + course_id, 
			{withCredentials: true}
		).pipe(
			map(res => {
				return this.extractData_getAssessmentItemConfigs(res);
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}
	private extractData_getAssessmentItemConfigs(res: any): Array<AssessmentItemConfig> {
		if (res.success) {
			let configs = new Array<AssessmentItemConfig>();
			for (var i = 0; i < res.configs.length; i ++) {
				configs.push(this.createAssessmentItemConfigObject(res.configs[i]));
			}
			return configs;
		} else {
			// alert(res.message);
			return null;
		}
	}

}


