import { NgModule }             from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AccountComponent } from "./account/account.component";
import { ConfigureCourseComponent } from "./configure-course/configure-course.component";
import { CoursesComponent } from "./courses/courses.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DoCourseComponent } from "./do-course/do-course.component";
import { ExploreCourseComponent } from "./explore-course/explore-course.component";
import { InvitationKeyMissingComponent } from "./invitation-key-missing/invitation-key-missing.component";
import { LeaderboardComponent } from "./leaderboard/leaderboard.component";
import { LogInComponent } from "./log-in/log-in.component";
import { LoopsComponent } from "./loops/loops.component";
import { ManageTasksComponent } from "./manage-tasks/manage-tasks.component";
import { ManageTeamComponent } from "./manage-team/manage-team.component";
import { ManageTeamsComponent } from "./manage-teams/manage-teams.component";
import { ModulesComponent } from "./modules/modules.component";
import { QueuedItemsComponent } from "./queued-items/queued-items.component";
import { RatingHistoryGraphComponent } from "./rating-history-graph/rating-history-graph.component";
import { ReportComponent } from "./report/report.component";
import { SettingsComponent } from "./settings/settings.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { SVNComponent } from "./svn/svn.component";
import { TestComponent }  from "./test/test.component";
import { TestCompleteComponent } from "./test-complete/test-complete.component";
import { TestIntroComponent } from "./test-intro/test-intro.component";
import { TreeGlobalComponent } from "./tree-global/tree-global.component";
import { TreeRadialComponent } from "./tree-radial/tree-radial.component";
import { TreeScorecardComponent } from "./tree-scorecard/tree-scorecard.component";
import { WelcomeComponent }  from "./welcome/welcome.component";

const routes: Routes = [
	{path: "", component: WelcomeComponent}, 
    {path: "account", component: AccountComponent}, 
    {path: "configure-course/:id", component: ConfigureCourseComponent}, 
    {path: "courses", component: CoursesComponent}, 
    {path: "dashboard", component: DashboardComponent}, 
    {path: "do-course/:course_id", component: DoCourseComponent}, 
    {path: "do-course/:course_id/:user_id", component: DoCourseComponent}, 
    {path: "explore-course/:id", component: ExploreCourseComponent},
    {path: "invitation-key-missing", component: InvitationKeyMissingComponent}, 
    {path: "leaderboard/:team_id/:course_id", component: LeaderboardComponent}, 
    {path: "log-in", component: LogInComponent}, 
    {path: "loops", component: LoopsComponent}, 
    {path: "manage-tasks", component: ManageTasksComponent}, 
    {path: "manage-team/:id", component: ManageTeamComponent}, 
    {path: "manage-teams", component: ManageTeamsComponent}, 
    {path: "modules", component: ModulesComponent}, 
    {path: "queued-items", component: QueuedItemsComponent}, 
    // {path: "rating-history-graph/:user_id/:assessment_item_id", component: RatingHistoryGraphComponent}, 
    {path: "report", component: ReportComponent}, 
    {path: "settings", component: SettingsComponent}, 
    {path: "sign-up", component: SignUpComponent}, 
    {path: "sign-up/:team_invitation_key", component: SignUpComponent}, 
    {path: "svn-ea", component: SVNComponent}, 
    {path: "test", component: TestComponent}, 
    {path: "test-complete", component: TestCompleteComponent}, 
    {path: "test-intro", component: TestIntroComponent}, 
    {path: "tree-global", component: TreeGlobalComponent}, 
    {path: "tree-radial", component: TreeRadialComponent}, 
    {path: "tree-radial/:loop_items", component: TreeRadialComponent}, 
];

@NgModule({
    imports: [ RouterModule.forRoot(routes, {useHash: true})],
    exports: [ RouterModule ]
})
export class AppRoutingModule {}


