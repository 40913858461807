import { 
	Component, 
	ElementRef, 
	OnInit, 
	ViewChild
} from "@angular/core";

import { interval } from "rxjs";

import { trigger, state, style, animate, transition } from "@angular/animations";

import { AppSettings } from "../app-settings";

@Component({
	selector: "rocket",
	templateUrl: "./rocket.component.html",
	styleUrls: ["./rocket.component.css"], 
	animations: [
	    trigger("move_state", [
			state("present", style({top: "-300px"})),
			transition("void => *", [
				style({top: "100%"}),
				animate(1000)
			]),
			transition("* => void", [
				//
			])
		]), 
	]
})
export class RocketComponent implements OnInit {

	@ViewChild("rocket", {static: true}) rocket: ElementRef;

	private visible = false;
	private frame_index = 0;
	private readonly FRAMES = ["rocket.png"];

	constructor(
		//
	) {
		//
	}

	ngOnInit(): void {
		interval(500).subscribe(x => {
			this.frame_index = (this.frame_index + 1) % this.FRAMES.length;
		});
	}

	public start_animation(): void {
		this.visible = true;
	}

}


