import { 
	Component, 
	OnInit
} from '@angular/core';

import { Router } from '@angular/router'

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { UserService } from '../services/user.service';

@Component({
	selector: 'settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

	private improvement_foci: Array<any>;
	private strategy_types: Array<string>;
	private tenure_types: Array<string>;

	private user_strategies: Array<boolean>;
	private user_tenure: number;

	private waitingForServerResponse = false;

	constructor(
		private router: Router, 
		private userService: UserService, 
	) {
		if (this.userService.getLoggedInUser() == null) {
			this.router.navigate(["/"]);
			return;
		}
	}

	ngOnInit(): void {
		this.userService.getUserSettings().subscribe(
			res => {
				this.improvement_foci = res.improvement_foci;
				this.strategy_types = res.strategy_types;
				this.tenure_types = res.tenure_types;

				this.user_strategies = new Array<boolean>();
				for (var _ = 0; _ < this.strategy_types.length; _ ++) {
					this.user_strategies.push(res.user_strategies.includes(_));
				}

				if (res.user_tenure) this.user_tenure = res.user_tenure;
			
				for (var new_index = 0; new_index < res.user_improvement_foci.length; new_index ++) {
					let id = res.user_improvement_foci[new_index];
					for (var old_index = 0; old_index < this.improvement_foci.length; old_index ++) {
						if (this.improvement_foci[old_index].id == id) {
							this.improvement_foci.splice(new_index, 0, this.improvement_foci.splice(old_index, 1)[0]);
							break;
						}
					}
				}
			}, 
			error => {
				// Do nothing.
			}
		);
	}

	private onFocusDrop(event: CdkDragDrop<any[]>) {
		moveItemInArray(this.improvement_foci, event.previousIndex, event.currentIndex);
	}

	private saveSettings(): void {
		this.waitingForServerResponse = true;

		let strategy_override = new Array<number>();
		for (var _ = 0; _ < this.user_strategies.length; _ ++) {
			if (this.user_strategies[_]) strategy_override.push(_);
		}

		let focus_override = new Array<number>();
		this.improvement_foci.forEach(focus => focus_override.push(focus.id));

		this.userService.setUserSettings(strategy_override, this.user_tenure, focus_override).subscribe(
			res => {
				this.waitingForServerResponse = false;
			}, 
			error => {
				this.waitingForServerResponse = false;
			}
		);
	}

	private resetSettings(): void {
		this.waitingForServerResponse = true;
	}

}


