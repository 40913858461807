import { 
	Component, 
	EventEmitter, 
	OnInit, 
	Output, 
} from '@angular/core';

import { PurchaseService } from '../services/purchase.service';

declare var StripeCheckout: any;

@Component({
	selector: 'stripe-checkout',
	templateUrl: './stripe-checkout.component.html',
	styleUrls: ['./stripe-checkout.component.css']
})
export class StripeCheckoutComponent implements OnInit {

	private checkout_handler: any;

	private waitingForServerResponse = false;
	private paymentSucceeded = false;

	constructor(
		private purchaseService: PurchaseService, 
	) {
		//
	}

	ngOnInit(): void {
		let _this = this;
		this.checkout_handler = StripeCheckout.configure({
			key: 'pk_live_nUHyl5L2HG7xa5kbatNMtuvD',
			image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
			locale: 'auto',
			token: function(token) {
				_this.waitingForServerResponse = true;
				_this.purchaseService.completePurchase(token).subscribe(
					res => {
						_this.waitingForServerResponse = false;
						if (res.success) {
							_this.paymentSucceeded = true;
							alert("Thanks for your purchase!");
						} else {
							alert("An error occurred.");
						}
					}, 
					error => {
						_this.waitingForServerResponse = false;
					}
				);
			}
		});
	}

	private buyTest(): void {
		this.checkout_handler.open({
			name: 'Retool',
			description: 'SVN Exemplary Advisor Profile',
			amount: 50 // 14500
		});
	}

}


