import { 
	Component, 
	EventEmitter, 
	Input, 
	OnInit, 
	Output, 
} from '@angular/core';

import { Router } from '@angular/router'

import { AppSettings } from '../app-settings';

import { CourseService } from '../services/course.service';
import { RatingService } from '../services/rating.service';

import { AssessmentItem } from '../models/assessment-item';
import { Course } from '../models/course';
import { Rating } from '../models/rating';

@Component({
	selector: 'rating-history',
	templateUrl: './rating-history.component.html',
	styleUrls: ['./rating-history.component.css']
})
export class RatingHistoryComponent implements OnInit {

	@Input() course: Course;
	@Input() ratingsForCourse: Array<Rating>;
	@Input() teamAveragesForCourse: Array<Rating>;
	@Input() user_id: number;
	@Input() course_id: number;

	private APP_SETTINGS = AppSettings;

	// item.id => Array<Rating>
	private ratingsByItem: any = {};

	constructor(
		private courseService: CourseService, 
		private ratingService: RatingService, 
		private router: Router, 
	) {
		//
	}

	ngOnInit(): void {
		if (this.ratingsForCourse && this.course) this.processRatings();

		if (!this.ratingsForCourse) {
			this.ratingService.getRatingsForCourse(this.user_id, this.course_id).subscribe(
				ratings => {
					this.ratingsForCourse = ratings;
					if (this.course) this.processRatings();
				}, 
				error => {
					// Do nothing.
				}
			);
		}

		if (!this.teamAveragesForCourse) {
			this.ratingService.getTeamAveragesForCourse(this.user_id, this.course_id).subscribe(
				averages => {
					this.teamAveragesForCourse = averages;
				}, 
				error => {
					// Do nothing.
				}
			);
		}

		if (!this.course) {
			this.courseService.getCourseAsTeamMember(this.course_id, this.user_id).subscribe(
				res => {
					this.course = res.course;
					if (this.ratingsForCourse) this.processRatings();
				}, 
				error => {
					// Do nothing.
				}
			);
		}
	}

	private getTeamAveragesForItem(item_id: number): any {
		for (var i = 0; i < this.teamAveragesForCourse.length; i ++) {
			if (this.teamAveragesForCourse[i].assessment_item_id == item_id) return (this.teamAveragesForCourse[i] as any).averages;
		}
	}

	private processRatings(): void {
		this.course.assessment_items.forEach(item => {
			if (!this.ratingsByItem[item.id]) this.ratingsByItem[item.id] = [];
		});

		this.ratingsForCourse.forEach(rating => {
			if (!this.ratingsByItem[rating.assessment_item_id]) this.ratingsByItem[rating.assessment_item_id] = [];
			this.ratingsByItem[rating.assessment_item_id].push(rating);
		});
	}

	private getAverageRating(item_id: number): number {
		var ave = 0;
		for (var i = 0; i < this.ratingsByItem[item_id].length; i ++) {
			ave += this.ratingsByItem[item_id][i].rating;
		}
		ave = ave / this.ratingsByItem[item_id].length;
		ave = Math.round(ave * 10) / 10;
		return ave;
	}

	private getNumRatingsText(item_id: number): string {
		return "(" + this.ratingsByItem[item_id].length + " rating" + (this.ratingsByItem[item_id].length == 1 ? "" : "s") + ")";
	}

	private navToGraph(item: AssessmentItem): void {
		// if (this.ratingsForItem.length > 0) this.router.navigate(["/rating-history-graph/" + this.user.id + "/" + this.item.item_id]);
		item.display_rating_history = !item.display_rating_history;
	}

}


