import { 
	Component, 
	OnInit
} from '@angular/core';

import { Router } from '@angular/router'

import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { TeamService } from '../services/team.service';
import { UserService } from '../services/user.service';

import { Team } from '../models/team';

@Component({
	selector: 'manage-teams',
	templateUrl: './manage-teams.component.html',
	styleUrls: ['./manage-teams.component.css']
})
export class ManageTeamsComponent implements OnInit {

	private teams: Array<Team>;

	private team_name_control = new FormControl('', [Validators.required]);
	private createTeamForm = new FormGroup({
		name: this.team_name_control
	});

	private waitingForServerResponse = false;

	constructor(
		private router: Router, 
		private teamService: TeamService, 
		private userService: UserService
	) {
		if (this.userService.getLoggedInUser() == null) {
			this.router.navigate(["/"]);
			return;
		}
	}

	ngOnInit(): void {
		this.teamService.getTeamsAsCoach().subscribe(
			teams => {
				this.teams = teams;
			}, 
			error => {
				// Do nothing.
			}
		);
	}

	private getErrorMessage_team_name(): string {
		return this.team_name_control.hasError('required') ? 'You must enter a value' : '';
	}

	private submitCreateTeamForm(): void {
		this.waitingForServerResponse = true;
		this.teamService.createTeam(this.team_name_control.value).subscribe(
			team => {
				this.waitingForServerResponse = false;
				this.teams.push(team);
			}, 
			error => {
				this.waitingForServerResponse = false;
			}
		);
	}

	private deleteTeam(team: Team): void {
		if (!confirm("Are you sure you wish to delete this team?")) return;

		this.waitingForServerResponse = true;
		this.teamService.deleteTeam(team).subscribe(
			empty => {
				this.waitingForServerResponse = false;
				for (var i = 0; i < this.teams.length; i ++) {
					if (this.teams[i].id == team.id) {
						this.teams.splice(i, 1);
						break;
					}
				}
			}, 
			error => {
				this.waitingForServerResponse = false;
			}
		);
	}

}


