import { 
	Component, 
	OnInit
} from '@angular/core';

import { Router } from '@angular/router'

import { trigger, state, style, animate, transition } from '@angular/animations';

import { AppSettings } from '../app-settings';

import { ModuleService } from '../services/module.service';
import { UserService } from '../services/user.service';

import { Module } from '../models/module';

@Component({
	selector: 'modules',
	templateUrl: './modules.component.html',
	styleUrls: ['./modules.component.css'], 
	animations: [
	    trigger('visibleState', [
			state('visible', style({opacity: 1.0})),
			state('void', style({opacity: 0.0})),
			transition('void => *', animate('500ms ease-out')), 
			transition('* => void', animate('500ms ease-in'))
	    ]), 
	]
})
export class ModulesComponent implements OnInit {

	private APP_SETTINGS = AppSettings;

	private modules: Array<Module>;

	constructor(
		private moduleService: ModuleService, 
		private router: Router, 
		private userService: UserService, 
	) {
		if (this.userService.getLoggedInUser() == null) {
			this.router.navigate(["/"]);
			return;
		}
	}

	ngOnInit(): void {
		this.moduleService.getModules().subscribe(
			res => {
				this.modules = res;
			}, 
			error => {
				// Do nothing.
			}
		);
	}

	private get_display_score(raw_score: number): string {
		return Math.round(raw_score).toString();
	}

}


