import { CourseSubscription } from './course-subscription';
import { TeamMembership } from './team-membership';

export class Team {
    id: number;
    user_id: number;
    name: string;
    memberships: Array<TeamMembership> = new Array<TeamMembership>();
    course_subscriptions: Array<CourseSubscription> = new Array<CourseSubscription>();

  	constructor(
        id: number, 
        user_id: number, 
        name: string,
    ) {
        this.id = id;
        this.user_id = user_id;
        this.name = name;
    }

}


