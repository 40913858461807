import { 
	Component, 
	OnInit
} from "@angular/core";

import { Router } from "@angular/router"

import { trigger, state, style, animate, transition } from "@angular/animations";

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { TaskService } from "../services/task.service";
import { UserService } from "../services/user.service";

import { Task } from "../models/task";

@Component({
	selector: "manage-tasks",
	templateUrl: "./manage-tasks.component.html",
	styleUrls: ["./manage-tasks.component.css"], 
	animations: [
	  //   trigger("module_state", [
			// state("visible", style({height: "auto",marginBottom: "20px"})),
			// state("void", style({height: "0px", marginBottom: "0px"})),
			// transition("void => *", animate("500ms ease-out")), 
			// transition("* => void", animate("500ms ease-in"))
	  //   ]), 
	]
})
export class ManageTasksComponent implements OnInit {

	private modules: Array<any>;

	private waitingForServerResponse = false;

	constructor(
		private router: Router, 
		private task_service: TaskService, 
		private user_service: UserService
	) {
		if (this.user_service.getLoggedInUser() == null) {
			this.router.navigate(["/"]);
			return;
		}
	}

	ngOnInit(): void {
		this.task_service.get_all_tasks().subscribe(
			modules => {
				this.modules = modules;
				this.modules.forEach(module => {
					module.hidden = true;
				});
			}, 
			error => {
				// Do nothing.
			}
		);
	}

	private add_task(module: any): void {
		module.tasks.push(new Task(-1, module.module_id, -1, "New task", new Date().getTime(), false));
	}

	private delete_task(module: any, task:Task): void {
		if (confirm("Are you sure?")) module.tasks.splice(module.tasks.indexOf(task), 1);
	}

	private on_task_drop(event: CdkDragDrop<any[]>, module: any) {
		moveItemInArray(module.tasks, event.previousIndex, event.currentIndex);
	}

	private save_tasks(): void {
		this.waitingForServerResponse = true;
		this.task_service.save_tasks(this.modules).subscribe(
			modules_new => {
				this.modules.forEach(module => {
					for (var i = 0; i < modules_new.length; i ++) {
						if (module.module_id == modules_new[i].module_id) {
							modules_new[i].hidden = module.hidden;
							break;
						}
					}
				});
				this.modules = modules_new;
				this.waitingForServerResponse = false;
			}, 
			error => {
				this.waitingForServerResponse = false;
			}
		);
	}

}


