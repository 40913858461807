import { 
	Component, 
	OnInit
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router'

import { trigger, state, style, animate, transition } from '@angular/animations';

import { AssessmentItemConfigService } from '../services/assessment-item-config.service';
import { CourseService } from '../services/course.service';
import { UserService } from '../services/user.service';

import { AssessmentItemConfig } from '../models/assessment-item-config';
import { Course } from '../models/course';


@Component({
	selector: 'configure-course',
	templateUrl: './configure-course.component.html',
	styleUrls: ['./configure-course.component.css'], 
	animations: [
	    trigger('dropdownState', [
	    	state('short', style({height: 'auto', marginTop: '16px', marginBottom: '0px', lineHeight: '22px'})),
			state('tall', style({height: 'auto', marginTop: '16px', marginBottom: '16px', lineHeight: '22px'})),
			state('void', style({height: '0px', marginTop: '0px', marginBottom: '0px', lineHeight: '0px'})),
			transition('void => *', animate('500ms ease-out')), 
			transition('* => void', animate('500ms ease-in'))
	    ])
	]
})
export class ConfigureCourseComponent implements OnInit {

	private course: Course;
	private configs: Array<AssessmentItemConfig>;

	constructor(
		private assessmentItemConfigService: AssessmentItemConfigService, 
		private courseService: CourseService, 
		private route: ActivatedRoute, 
		private router: Router, 
		private userService: UserService
	) {
		if (this.userService.getLoggedInUser() == null) {
			this.router.navigate(["/"]);
			return;
		}
	}

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			var course_id = +params['id'];

			if (course_id) {
				this.courseService.getCourseAsTeamMember(course_id, null).subscribe(
					res => {
						this.course = res.course;
						if (this.configs) this.setItemsStateFromConfigs();
					}, 
					error => {
						// Do nothing.
					}
				);

				this.assessmentItemConfigService.getAssessmentItemConfigs(course_id).subscribe(
					res => {
						this.configs = res;
						if (this.course) this.setItemsStateFromConfigs();
					}, 
					error => {
						// Do nothing.
					}
				);
			}
		});
	}

	private setItemsStateFromConfigs(): void {
		this.course.assessment_items.forEach(item => {
			item.is_active = false;
			for (var _ = 0; _ < this.configs.length; _ ++) {
				let config = this.configs[_];
				if (config.assessment_item_id == item.id) {
					item.is_active = config.is_active;
					item.freq = config.freq;
					item.start_date = config.start_date;
					break;
				}
			}
		});
	}

	private toggle_is_active(item: any): void {
		setTimeout(() => {
			item.is_active = !item.is_active;
			this.saveConfig(item);
		}, 0);
	}

	private saveConfig(item: any): void {
		this.assessmentItemConfigService.saveAssessmentItemConfig(new AssessmentItemConfig(null, item.id, this.userService.getLoggedInUser().id, this.course.id, item.is_active, item.freq, item.start_date)).subscribe(
			res => {
				// Do nothing.
			}, 
			error => {
				// Do nothing.
			}
		);
	}

}


