import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";

import { CdkDragDrop, DragDropModule, moveItemInArray } from "@angular/cdk/drag-drop";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatNativeDateModule } from "@angular/material";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSortModule } from "@angular/material/sort";

import { FileSelectDirective } from "ng2-file-upload";

import { DndListModule } from "ngx-drag-and-drop-lists";

import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";

import { AppComponentService } from "./services/appcomponent.service";
import { AssessmentItemConfigService } from "./services/assessment-item-config.service";
import { CourseService } from "./services/course.service";
import { ModuleService } from "./services/module.service";
import { PromptsService } from "./services/prompts.service";
import { PurchaseService } from "./services/purchase.service";
import { RatingService } from "./services/rating.service";
import { TaskService } from "./services/task.service";
import { TeamService } from "./services/team.service";
import { TestService } from "./services/test.service";
import { UserService } from "./services/user.service";

import { AccountComponent } from "./account/account.component";
import { AppComponent } from "./app.component";
import { ConfigureCourseComponent } from "./configure-course/configure-course.component";
import { CourseComponent } from "./course/course.component";
import { CoursesComponent } from "./courses/courses.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DoCourseComponent } from "./do-course/do-course.component";
import { ExploreCourseComponent } from "./explore-course/explore-course.component";
import { InvitationKeyMissingComponent } from "./invitation-key-missing/invitation-key-missing.component";
import { LeaderboardComponent } from "./leaderboard/leaderboard.component";
import { LeaderboardNewComponent } from "./leaderboard-new/leaderboard-new.component";
import { LogInComponent } from "./log-in/log-in.component";
import { LoopsComponent } from "./loops/loops.component";
import { ManageTasksComponent } from "./manage-tasks/manage-tasks.component";
import { ManageTeamComponent } from "./manage-team/manage-team.component";
import { ManageTeamsComponent } from "./manage-teams/manage-teams.component";
import { ModulesComponent } from "./modules/modules.component";
import { QueuedItemsComponent } from "./queued-items/queued-items.component";
import { RatingHistoryComponent } from "./rating-history/rating-history.component";
import { RatingHistoryGraphComponent } from "./rating-history-graph/rating-history-graph.component";
import { ReportComponent } from "./report/report.component";
import { RocketComponent } from "./rocket/rocket.component";
import { SettingsComponent } from "./settings/settings.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { SliderComponent } from "./slider/slider.component";
import { StaticSliderComponent } from "./slider-static/slider-static.component";
import { StripeCheckoutComponent } from "./stripe-checkout/stripe-checkout.component";
import { SVNComponent } from "./svn/svn.component";
import { TestComponent } from "./test/test.component";
import { TestCompleteComponent } from "./test-complete/test-complete.component";
import { TestIntroComponent } from "./test-intro/test-intro.component";
import { TreeGlobalComponent } from "./tree-global/tree-global.component";
import { TreeRadialComponent } from "./tree-radial/tree-radial.component";
import { TreeScorecardComponent } from "./tree-scorecard/tree-scorecard.component";
import { WelcomeComponent } from "./welcome/welcome.component";

@NgModule({
	declarations: [
		AccountComponent, 
		AppComponent, 
		ConfigureCourseComponent, 
		CourseComponent, 
		CoursesComponent, 
		DashboardComponent, 
		DoCourseComponent, 
		ExploreCourseComponent, 
		FileSelectDirective, 
		InvitationKeyMissingComponent, 
		LeaderboardComponent, 
		LeaderboardNewComponent, 
		LogInComponent, 
		LoopsComponent, 
		ManageTasksComponent, 
		ManageTeamComponent, 
		ManageTeamsComponent, 
		ModulesComponent, 
		QueuedItemsComponent, 
		RatingHistoryComponent, 
		RatingHistoryGraphComponent, 
		ReportComponent, 
		RocketComponent, 
		SettingsComponent, 
		SignUpComponent, 
		SliderComponent, 
		StaticSliderComponent, 
		StripeCheckoutComponent, 
		SVNComponent, 
		TestComponent, 
		TestCompleteComponent, 
		TestIntroComponent, 
		TreeGlobalComponent, 
		TreeRadialComponent, 
		TreeScorecardComponent, 
		WelcomeComponent, 
	],
	imports: [
		AppRoutingModule, 
		BrowserModule, 
		BrowserAnimationsModule, 
		DndListModule, 
		DragDropModule, 
		FormsModule, 
		HttpClientModule, 
		MatButtonModule, 
		MatCheckboxModule, 
		MatDatepickerModule, 
		MatFormFieldModule, 
		MatIconModule, 
		MatInputModule, 
		MatListModule, 
		MatMenuModule, 
		MatNativeDateModule, 
		MatProgressBarModule, 
		MatRadioModule, 
		MatSelectModule, 
		MatSlideToggleModule, 
		MatSortModule, 
		NgxMaterialTimepickerModule, 
		ReactiveFormsModule
	],
	providers: [
		AppComponentService, 
		AssessmentItemConfigService, 
		CourseService, 
		ModuleService, 
		PromptsService, 
		PurchaseService, 
		RatingService, 
		TaskService, 
		TeamService, 
		TestService, 
		UserService, 
		{provide: LocationStrategy, useClass: HashLocationStrategy}, 
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule { }


