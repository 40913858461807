export class CourseSubscription {
    id: number;
    team_id: number;
    course_id: number;
    course_name: string;

  	constructor(
        src: any
    ) {
        this.id = src.id;
        this.team_id = src.team_id;
        this.course_id = src.module_id;
        this.course_name = src.course_name;
    }

}


