import { 
	Component, 
	OnInit
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router'

import { TestService } from '../services/test.service';
import { UserService } from '../services/user.service';

@Component({
	selector: 'svn',
	templateUrl: './svn.component.html',
	styleUrls: ['./svn.component.css']
})
export class SVNComponent implements OnInit {

	private test_invitation_key: string = null;

	constructor(
		private route: ActivatedRoute, 
		private router: Router, 
		private testService: TestService, 
		private userService: UserService, 
	) {
		// 
	}

	ngOnInit(): void {
		// 
	}

}


