import { 
	Component, 
	OnInit
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router'

import { AppSettings } from '../app-settings';

import { CourseService } from '../services/course.service';
import { RatingService } from '../services/rating.service';
import { UserService } from '../services/user.service';

@Component({
	selector: 'leaderboard',
	templateUrl: './leaderboard.component.html',
	styleUrls: ['./leaderboard.component.css']
})
export class LeaderboardComponent implements OnInit {

	private APP_SETTINGS = AppSettings;

	private ONE_TO_TEN: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

	private BASE_ASSESSMENT_ITEM_ICON_WIDTH: number = 20;

	private course_name: string;
	private leaderboard_data: any;

	constructor(
		private courseService: CourseService, 
		private route: ActivatedRoute, 
		private router: Router, 
		private ratingService: RatingService, 
		private userService: UserService, 
	) {
		if (this.userService.getLoggedInUser() == null) {
			this.router.navigate(["/"]);
			return;
		}
	}

	ngOnInit(): void {
		this.route.params.subscribe(params => {

			let team_id = +params['team_id'];
			let course_id = +params['course_id'];

			this.courseService.getCourseAsCoach(course_id).subscribe(
				res => {
					this.course_name = res.name;
				}, 
				error => {
					// Do nothing.
				}
			);

			this.ratingService.getLeaderboardData(team_id, course_id).subscribe(
				res => {
					this.leaderboard_data = res;
				}, 
				error => {
					// Do nothing.
				}
			);

		});
	}

	private getAssessmentItemIconWidth(slider_val: number, icon_val: number): string {
		if (slider_val >= icon_val) {
			return this.BASE_ASSESSMENT_ITEM_ICON_WIDTH + "px";
		} else {
			return ((slider_val - icon_val + 1) * this.BASE_ASSESSMENT_ITEM_ICON_WIDTH) + "px";
		}
	}

}


