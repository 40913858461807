import { AssessmentItem } from './assessment-item';

export class Course {
    id: number;
    name: string;
    assessment_items: Array<AssessmentItem>;
    d3_tree: any;

  	constructor(
        id: number, 
        name: string, 
        assessment_items: Array<AssessmentItem>, 
        d3_tree: any, 
    ) {
        this.id = id;
        this.name = name;
        this.assessment_items = assessment_items;
        this.d3_tree = d3_tree;
    }

}


