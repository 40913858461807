import { Injectable } from '@angular/core';

import { AppComponent } from '../app.component';

@Injectable()
export class AppComponentService {

	private appComponent: AppComponent;

	getAppComponent(): AppComponent {
		return this.appComponent;
	}
	setAppComponent(appComponent: AppComponent) {
		this.appComponent = appComponent;
	}

}


