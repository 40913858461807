import { 
	Component, 
	ElementRef, 
	OnInit, 
	QueryList, 
	ViewChildren
} from '@angular/core';

import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';

import { interval } from 'rxjs';

import { AppSettings } from './app-settings';

import { DoCourseComponent } from './do-course/do-course.component';
import { ExploreCourseComponent } from './explore-course/explore-course.component';

import { AppComponentService } from './services/appcomponent.service';
import { UserService } from './services/user.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

	@ViewChildren('background') backgrounds: QueryList<ElementRef>;

	constructor(
		private route: ActivatedRoute, 
		private appComponentService: AppComponentService, 
		private router: Router, 
		private userService: UserService
	) {
		this.router.events.subscribe(event => {
		    if (event instanceof NavigationEnd && this.userService.getLoggedInUser()) localStorage.setItem('lastLocation', event.url);
		});

		appComponentService.setAppComponent(this);
	}

	ngOnInit(): void {
		let href = window.location.href;
		this.userService.loadLoggedInUser().subscribe(
			user => {
				// if (localStorage.getItem("lastLocation") && localStorage.getItem("lastLocation").indexOf("?") == -1) this.router.navigate([localStorage.getItem("lastLocation")]);
				let hash_index = href.indexOf("#/");
				if (hash_index != -1) {
					this.router.navigate([href.substring(hash_index + 2)]);
				}
			}, 
			error => {
				// Do nothing.
			}
		);

		// var _this = this;
		// setInterval(function() {
		// 	if (_this.userService != null && _this.userService.getLoggedInUser() != null) {
		// 		_this.userService.heartbeat().subscribe(
		// 			res => {}, error => {}
		// 		);
		// 	}
		// }, 10000);
	}

	private openReportsLink(): void {
		window.open(AppSettings.WEB_ROOT + 'reports/' + this.userService.getLoggedInUser().invitation_key + '.pdf');
	}

	getScreenWidth(): number {
		var width: number;
		this.backgrounds.forEach(background => {
			width = background.nativeElement.clientWidth;
		});
		return width;
	}
	getScreenHeight(): number {
		var height: number;
		this.backgrounds.forEach(background => {
			height = background.nativeElement.clientHeight;
		});
		return height;
	}

	private doCourseComponent: DoCourseComponent = null;
	public setDoCourseComponent(doCourseComponent: DoCourseComponent) {
		this.doCourseComponent = doCourseComponent;
	}

	private exploreCourseComponent: ExploreCourseComponent = null;
	public setExploreCourseComponent(exploreCourseComponent: ExploreCourseComponent) {
		this.exploreCourseComponent = exploreCourseComponent;
	}

	private isExternalDesign(): boolean {
		return this.router.url.length == 1 || this.router.url.indexOf('/svn-ea') == 0;
	}

	private shouldDisplayNav(): boolean {
		return this.userService.getLoggedInUser() && this.router.url.indexOf('/log-in') == -1 && this.router.url.indexOf('/test') == -1 && !this.isExternalDesign();
	}

	private shouldDisplayBackBtn(): boolean {
		return this.router.url.indexOf('/do-course') == 0 || this.router.url.indexOf('/explore-course') == 0 || this.router.url.indexOf('/manage-team') == 0;
	}
	private backBtnClicked(): void {
		if (this.router.url.indexOf('/do-course') == 0 && this.doCourseComponent != null) {
			this.doCourseComponent.backBtnClicked();
		} else if (this.router.url.indexOf('/explore-course') == 0 && this.exploreCourseComponent != null) {
			this.exploreCourseComponent.backBtnClicked();
		} else if (this.router.url.indexOf('/manage-team') == 0) {
			this.router.navigate(['manage-teams']);
		}
	}

	private loggingOut = false;
	private logOut(): void {
		this.loggingOut = true;
		this.userService.logOut().subscribe(
			success => {
				this.loggingOut = false;
				if (success) this.router.navigate(["/"]);
			}, 
			error => {
				this.loggingOut = false;
			}
		);
	}

}


