export class User {
    id: number;
    fname: string;
    lname: string;
  	email: string;
    phone: string;
  	password1: string;
    password2: string;
    psych_model: any;
    selected_facets: Array<string>;
    is_coach: boolean;
    photo_filename: string;
    thumbnail_filename: string;
    context: number;
    has_reports: boolean;
    invitation_key: string;
    timezone: string;
    call_availability: Array<Array<any>>;
    team_leader: number;

  	constructor(
        id: number, 
        fname: string, 
        lname: string, 
        email: string, 
        phone: string, 
        psych_model: any, 
        selected_facets_str: string, // A comma-separated list of keys
        is_coach: boolean, 
        photo_filename: string, 
        thumbnail_filename: string, 
        context: number, 
        has_reports: boolean, 
        invitation_key: string, 
        timezone: string, 
        call_availability: string, 
        team_leader: number, 
    ) {
        this.id = id;
        this.fname = fname;
        this.lname = lname;
        this.email = email;
        this.phone = phone;
        this.psych_model = psych_model;

        if (selected_facets_str.length == 0) {
            this.selected_facets = new Array();
        } else {
            this.selected_facets = selected_facets_str.split(",");
        }

        this.is_coach = is_coach;
        this.photo_filename = photo_filename;
        this.thumbnail_filename = thumbnail_filename;
        this.context = context;
        this.has_reports = has_reports;
        this.invitation_key = invitation_key;

        this.timezone = timezone;
        this.call_availability = JSON.parse(call_availability);

        this.team_leader = team_leader;
    }

    hasSelectedFacet(key: string): boolean {
        return (this.selected_facets.indexOf(key) > -1);
    }

    private computeHTMLClassForFacet(trait: string, facet_key: string): string {
        var tScore = this.psych_model[trait][facet_key].tScore;
        if (tScore <= 52) {
            return "result_bad";
        } else if (tScore < 54) {
            return "result_ok";
        } else if (tScore < 68) {
            return "result_good";
        } else {
            return "result_great";
        }
    }

}


