import { 
	Component, 
	OnInit
} from '@angular/core';

import { Router } from '@angular/router'

import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { UserService } from '../services/user.service';

@Component({
	selector: 'log-in',
	templateUrl: './log-in.component.html',
	styleUrls: ['./log-in.component.css']
})
export class LogInComponent implements OnInit {

	private email_control = new FormControl('', [Validators.required, Validators.email]);
	// private password_control = new FormControl('', [Validators.required, Validators.minLength(8)]);
	private password_control = new FormControl('', [Validators.required]);

	private logInForm = new FormGroup({
		email: this.email_control, 
		password: this.password_control
	});

	private hide_password = true;

	constructor(
		private router: Router, 
		private userService: UserService
	) {
		// Do nothing.
	}

	ngOnInit(): void {
		this.userService.loadLoggedInUser().subscribe(
			user => {
				if (user != null) this.router.navigate(["courses"]);
			}, 
			error => {
				//
			}
		);
	}

	private getErrorMessage_email(): string {
		return this.email_control.hasError('required') ? 'You must enter a value' : this.email_control.hasError('email') ? 'Not a valid email' : '';
	}
	private getErrorMessage_password(): string {
		return this.password_control.hasError('required') ? 'You must enter a value' : this.password_control.hasError('minlength') ? 'Must be at least 8 characters' : '';
	}

	private loggingIn = false;
	private submitLogInForm(): void {
		this.loggingIn = true;
		this.userService.logIn(this.email_control.value, this.password_control.value).subscribe(
			user => {
				this.loggingIn = false;
				if (user != null) this.router.navigate(["courses"]);
			}, 
			error => {
				this.loggingIn = false;
			}
		);
	}

	private requestingPasswordReset = false;
	private requestPasswordReset(): void {
		if (this.requestingPasswordReset) return;

		if (this.email_control.hasError('required')) {
			alert("Please enter your e-mail address, then click again to receive a password reset link via e-mail.");
			return;
		}
		if (this.email_control.hasError('email')) {
			alert("Please enter a valid e-mail address to receive a password reset link.");
			return;
		}

		this.requestingPasswordReset = true;
		this.userService.requestPasswordReset(this.email_control.value).subscribe(
			success => {
				this.requestingPasswordReset = false;
				if (success) alert("We have e-mailed you a password reset link.");
			}, 
			error => {
				this.requestingPasswordReset = false;
			}
		);
	}

}


