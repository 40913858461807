export class AssessmentItemConfig {
    id: number;
    assessment_item_id: number;
    user_id: number;
    course_id: number;
    is_active: boolean;
    freq: string;
    start_date: Date;

  	constructor(
        id: number, 
        assessment_item_id: number, 
        user_id: number, 
        course_id: number, 
        is_active: boolean, 
        freq: string, 
        start_date: Date
    ) {
        this.id = id;
        this.assessment_item_id = assessment_item_id;
        this.user_id = user_id;
        this.course_id = course_id;
        this.is_active = is_active;
        this.freq = freq;
        this.start_date = start_date;
    }

}


