import { 
	Component, 
	Input, 
	OnInit, 
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router'

import {Sort} from '@angular/material/sort';

import { AppSettings } from '../app-settings';

import { RatingService } from '../services/rating.service';
import { UserService } from '../services/user.service';

@Component({
	selector: 'leaderboard-new',
	templateUrl: './leaderboard-new.component.html',
	styleUrls: ['./leaderboard-new.component.css']
})
export class LeaderboardNewComponent implements OnInit {

	private APP_SETTINGS = AppSettings;

	@Input() team_id: number;
	@Input() course_id: number;
	private leaderboard_data: any;

	constructor(
		private route: ActivatedRoute, 
		private router: Router, 
		private ratingService: RatingService, 
		private userService: UserService, 
	) {
		if (this.userService.getLoggedInUser() == null) {
			this.router.navigate(["/"]);
			return;
		}
	}

	ngOnInit(): void {
		this.route.params.subscribe(params => {

			this.ratingService.get_leaderboard_data_new(this.team_id, this.course_id).subscribe(
				res => {
					this.leaderboard_data = res;
				}, 
				error => {
					// Do nothing.
				}
			);

		});
	}

	private sort_leaderboard(sort: Sort): void {
		const data = this.leaderboard_data.slice();
		if (!sort.active || sort.direction === '') {
			this.leaderboard_data = data;
			return;
		}

		this.leaderboard_data = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'name': return this.compare(a.name, b.name, isAsc);
				case 'num_role_plays': return this.compare(a.num_role_plays, b.num_role_plays, isAsc);
				case 'num_ratings': return this.compare(a.num_ratings, b.num_ratings, isAsc);
				case 'ave_rating': return this.compare(a.ave_rating, b.ave_rating, isAsc);
				case 'num_ratings_given': return this.compare(a.num_ratings_given, b.num_ratings_given, isAsc);
				default: return 0;
			}
		});
	}

	private compare(a: number | string, b: number | string, isAsc: boolean): number {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

}


