export class TeamMembership {
    id: number;
    team_id: number;
    user_id: number;
    member_fname: string;
    member_lname: string;
    member_name: string;
    member_thumbnail_filename: string;
    member_phone: string;
    rated: boolean = false;
    expanded: boolean = false;
    called: boolean = false;
    available: boolean = false;
    call_availability: Array<Array<any>>;
    video: string;

  	constructor(
        id: number, 
        team_id: number, 
        user_id: number, 
        member_fname: string, 
        member_lname: string, 
        member_name: string, 
        member_thumbnail_filename: string, 
        member_phone: string, 
        available: boolean, 
        call_availability: Array<Array<any>>, 
        video: string, 
    ) {
        this.id = id;
        this.team_id = team_id;
        this.user_id = user_id;
        this.member_fname = member_fname;
        this.member_lname = member_lname;
        this.member_name = member_name;
        this.member_thumbnail_filename = member_thumbnail_filename;
        this.member_phone = member_phone;
        this.available = available;
        this.call_availability = call_availability;
        this.video = video;
    }

}


