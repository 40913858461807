import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettings } from '../app-settings';

import { Rating } from '../models/rating';

@Injectable()
export class RatingService {

	constructor(
		private httpClient: HttpClient, 
		private router: Router
	) { }

	createRatingObject(src: any): Rating {
		if (src) {
			let r = new Rating(
				src.id, 
				src.user_id, 
				src.rater_id, 
				src.rating, 
				src.course_id, 
				src.assessment_item_id, 
				src.created_at
			);
			return r;
		} else {
			return null;
		}
	}

	getLeaderboardData(team_id: number, course_id: number): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-leaderboard-data?team_id=" + team_id + "&course_id=" + course_id, 
			{withCredentials: true}
		).pipe(
			map(res => {
				if ((res as any).success) {
					return (res as any).data;
				} else {
					return null;
				}
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

	get_leaderboard_data_new(team_id: number, course_id: number): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-leaderboard-data-new?team_id=" + team_id + "&course_id=" + course_id, 
			{withCredentials: true}
		).pipe(
			map(res => {
				if ((res as any).success) {
					return (res as any).leaderboard_data;
				} else {
					return null;
				}
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

	getAllUserRatings(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-all-user-ratings", 
			{withCredentials: true}
		).pipe(
			map(res => {
				if ((res as any).success) {
					return res;
				} else {
					return null;
				}
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

	getRatingsForCourse(user_id: number, course_id: number): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-ratings-for-course?user_id=" + user_id + "&course_id=" + course_id, 
			{withCredentials: true}
		).pipe(
			map(res => {
				return this.extractData_getRatingsForCourse(res);
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}
	private extractData_getRatingsForCourse(res: any): Array<Rating> {
		if (res.success) {
			let ratings = new Array<Rating>();
			for (var i = 0; i < res.ratings.length; i ++) {
				ratings.push(this.createRatingObject(res.ratings[i]));
			}
			return ratings;
		} else {
			// alert(res.message);
			return null;
		}
	}

	getTeamAveragesForCourse(user_id: number, course_id: number): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-team-averages-for-course?user_id=" + user_id + "&course_id=" + course_id, 
			{withCredentials: true}
		).pipe(
			map(res => {
				return this.extractData_getTeamAveragesForCourse(res);
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}
	private extractData_getTeamAveragesForCourse(res: any): Array<any> {
		if (res.success) {
			return (res as any).averages;
		} else {
			// alert(res.message);
			return null;
		}
	}

	getRatingsForItem(user_id: number, item_id: number): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-ratings-for-item?user_id=" + user_id + "&item_id=" + item_id, 
			{withCredentials: true}
		).pipe(
			map(res => {
				return this.extractData_getRatingsForItem(res);
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}
	private extractData_getRatingsForItem(res: any): Array<Rating> {
		if (res.success) {
			var ratings = new Array<Rating>();
			for (var i = 0; i < res.ratings.length; i ++) {
				ratings.push(this.createRatingObject(res.ratings[i]));
			}
			return ratings;
		} else {
			// alert(res.message);
			return null;
		}
	}

	saveRating(rating: Rating, queued_assessment_item_id: number): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "save-rating", 
			JSON.stringify({rating: rating, queued_assessment_item_id: queued_assessment_item_id}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return null;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

}


