import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettings } from '../app-settings';

import { TeamService } from './team.service';

import { AssessmentItem } from '../models/assessment-item';
import { Course } from '../models/course';

@Injectable()
export class CourseService {

	constructor(
		private httpClient: HttpClient, 
		private router: Router, 
		private teamService: TeamService, 
	) { }

	createCourseObject(src: any): Course {
		if (src) {
			let assessment_items = new Array<AssessmentItem>();
			src.assessment_items_flat.forEach(item => {
				assessment_items.push(new AssessmentItem(
					item.id, 
					item.name, 
					item.description, 
					item.question, 
					item.hidden
				));
			});
			let course = new Course(
				src.id, 
				src.name, 
				assessment_items, 
				src.d3_tree, 
			);
			return course;
		} else {
			return new Course(
					-1, 
					"", 
					new Array<AssessmentItem>(), 
					{id: 1, children: [], item: null}, 
				);
		}
	}

	hideItem(item_id: number): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "hide-item", 
			JSON.stringify({item_id: item_id}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return null;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

	unhideItemsForCourse(course_id: number): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "unhide-items-for-course", 
			JSON.stringify({course_id: course_id}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return null;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

	getCourseAsCoach(course_id: number): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-course-as-coach?id=" + course_id, 
			{withCredentials: true}
		).pipe(
			map(res => {
				return this.extractData_getCourseAsCoach(res);
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}
	private extractData_getCourseAsCoach(res: any): Course {
		if (res.success) {
			return this.createCourseObject(res.course);
		} else {
			// alert(res.message);
			return null;
		}
	}

	getCourseAsTeamMember(course_id: number, user_id_for_hidden_items: number): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-course-as-team-member?id=" + course_id + "&user_id_for_hidden_items=" + user_id_for_hidden_items, 
			{withCredentials: true}
		).pipe(
			map(res => {
				return this.extractData_getCourseAsTeamMember(res);
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}
	private extractData_getCourseAsTeamMember(res: any): any {
		if (res.success) {
			return {
				team: this.teamService.createTeamObject(res.team), 
				course: this.createCourseObject(res.course), 
				video: res.video, 
			};
		} else {
			// alert(res.message);
			return null;
		}
	}

	getCoursesAsCoach(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-courses-as-coach", 
			{withCredentials: true}
		).pipe(
			map(res => {
				return this.extractData_getCoursesAsCoach(res);
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}
	private extractData_getCoursesAsCoach(res: any): Array<Course> {
		if (res.success) {
			var courses = new Array<Course>();
			for (var i = 0; i < res.courses.length; i ++) {
				courses.push(this.createCourseObject(res.courses[i]));
			}
			return courses;
		} else {
			// alert(res.message);
			return null;
		}
	}

}


