import { 
	Component, 
	OnInit
} from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";

import { ModuleService } from "../services/module.service";
import { UserService } from "../services/user.service";

@Component({
	selector: "loops",
	templateUrl: "./loops.component.html",
	styleUrls: ["./loops.component.css"]
})
export class LoopsComponent implements OnInit {

	all_loops: Array<any> = [];
	all_borders: Array<any> = [];
	modules: Array<any> = null;

	private grid: Array<Array<boolean>> = [];

	// In slices:
	private GRID_DIM: number;
	private HALF_GRID_DIM: number;
	private slice_side_length: number;

	private show_tooltip: boolean = false;
	private tooltip_text_line_0: string = null;
	private tooltip_text_line_1: string = null;
	private tooltip_text_line_2: string = null;
	private tooltip_text_line_3: string = null;
	private tooltip_text_line_4: string = null;
	private tooltip_text_line_5: string = null;
	private tooltip_text_line_6: string = null;
	private tooltip_top: string = null;
	private tooltip_left: string = null;

	constructor(
		private module_service: ModuleService, 
		private route: ActivatedRoute, 
		private router: Router, 
		private user_service: UserService, 
	) {
		if (this.user_service.getLoggedInUser() == null) {
			this.router.navigate([ "/" ]);
			return;
		}
	}

	ngOnInit(): void {

		this.module_service.get_modules_global().subscribe(
			res => {

				this.modules = res.modules;

				var num_items_total = 0;
				res.loops.forEach(loop_raw => {
					loop_raw.assessment_items = JSON.parse(loop_raw.assessment_items);
					num_items_total += loop_raw.assessment_items.length;
				});
				console.log("num_items_total: " + num_items_total);

				this.GRID_DIM = Math.ceil(Math.pow(num_items_total, 0.5)) + 4;
				if (this.GRID_DIM % 2 == 1) this.GRID_DIM ++;
				this.HALF_GRID_DIM = this.GRID_DIM / 2;
				this.slice_side_length = Math.floor(1000.0 / this.GRID_DIM);

				// Initialize the grid. true means the square is occupied:
				for (var x = 0; x < this.GRID_DIM; x ++) {
					let column = [];
					for (var y = 0; y < this.GRID_DIM; y ++) {
						column.push(false);
					}
					this.grid.push(column);
				}

				// Sort loops:
				let scores: Array<number> = JSON.parse(res.scores);
				var max_score = 0;
				for (var i = 0; i < res.loops.length; i ++) {

					let loop = res.loops[i];

					var sum_scores = 0;
					loop.assessment_items.forEach(item => {
						sum_scores += scores[item];
					});
					let score = sum_scores / loop.assessment_items.length;
					if (isNaN(score)) score = 0;

					if (score > max_score) max_score = score;
					loop.score = score;

				}
				console.log("max_score: " + max_score);
				res.loops.sort((a, b) => (a.score > b.score) ? -1 : 1);

				for (var i = 0; i < res.loops.length; i ++) {
					var min_radius = 0;
					var loop = null;
					while (loop == null && min_radius < this.HALF_GRID_DIM) {
						let loop_raw = res.loops[i];
						loop = this.create_loop(loop_raw.assessment_items, min_radius, loop_raw.score / max_score, loop_raw.name);
						min_radius ++;
						// console.log("Failed to create loop for min_radius: " + min_radius);
					}
					if (loop == null) {
						console.log("*** Failed to create loop for:");
						console.log(res.loops[i]);
						// i --;
					} else {
						this.all_loops.push(loop);
					}
				}
				console.log("Finished creating loops.");

				console.log("Computing borders...");
				for (var loop_index = 0; loop_index < this.all_loops.length; loop_index ++) {
					let loop = this.all_loops[loop_index];
					for (var item_index = 0; item_index < loop.items.length; item_index ++) {
						let item = loop.items[item_index];

						var is_item_above = false;
						var is_item_below = false;
						var is_item_left = false;
						var is_item_right = false;
						for (var _item_index = 0; _item_index < loop.items.length; _item_index ++) {
							let _item = loop.items[_item_index];
							if (item.x == _item.x && item.y == _item.y + 1) is_item_above = true;
							if (item.x == _item.x && item.y == _item.y - 1) is_item_below = true;
							if (item.x == _item.x + 1 && item.y == _item.y) is_item_left = true;
							if (item.x == _item.x - 1 && item.y == _item.y) is_item_right = true;
						}

						if (!is_item_above) this.all_borders.push({
							top: (item.y * this.slice_side_length - 1) + "px", 
							left: (item.x * this.slice_side_length - 1) + "px", 
							height: "2px", 
							width: (this.slice_side_length + 2) + "px", 
						});
							if (!is_item_below) this.all_borders.push({
							top: ((item.y + 1) * this.slice_side_length - 1) + "px", 
							left: (item.x * this.slice_side_length - 1) + "px", 
							height: "2px", 
							width: (this.slice_side_length + 2) + "px", 
						});
						if (!is_item_left) this.all_borders.push({
							top: (item.y * this.slice_side_length) + "px", 
							left: (item.x * this.slice_side_length - 1) + "px", 
							height: this.slice_side_length + "px", 
							width: "2px", 
						});
						if (!is_item_right) this.all_borders.push({
							top: (item.y * this.slice_side_length) + "px", 
							left: ((item.x + 1) * this.slice_side_length - 1) + "px", 
							height: this.slice_side_length + "px", 
							width: "2px", 
						});

					}
				}

			}, 
			error => {
				// Do nothing.
			}
		);

	}

	compute_background_image(item: any, loop: any): string {
		if (item.tag == null) {
			return "none";
		} else if (item.tag == "retention") {
			return "url('/assets/tag_retention.png')";
		} else if (item.tag == "brain") {
			return "url('/assets/tag_brain.png')";
		} else if (item.tag == "knight") {
			return "url('/assets/tag_knight.png')";
		} else if (item.tag == "wrench") {
			return "url('/assets/tag_wrench.png')";
		}
	}

	compute_background_color(item: any, loop: any): string {
		return loop.is_active ? '#C0C0FF' : loop.background_color;
	}

	mouse_over_item(item: any, loop: any, evt: any): void {

		loop.is_active = true;

		this.show_tooltip = false;

		if (loop.name) {
			this.tooltip_text_line_0 = loop.name;
			this.show_tooltip = true;
		} else {
			this.tooltip_text_line_0 = null;
		}

		// if (item.orig_item && item.orig_item.category) {
		// 	this.tooltip_text_line_0 = item.orig_item.category;
		// 	this.show_tooltip = true;
		// } else {
		// 	this.tooltip_text_line_0 = null;
		// }

		// if (item.orig_item && item.orig_item.scorecard) {
		// 	this.tooltip_text_line_1 = item.orig_item.scorecard;
		// 	this.show_tooltip = true;
		// } else {
		// 	this.tooltip_text_line_1 = null;
		// }

		// if (item.orig_item && item.orig_item.partner) {
		// 	this.tooltip_text_line_2 = item.orig_item.partner;
		// 	this.show_tooltip = true;
		// } else {
		// 	this.tooltip_text_line_2 = null;
		// }

		// if (item.orig_item && item.orig_item.notes) {
		// 	this.tooltip_text_line_3 = item.orig_item.notes;
		// 	this.show_tooltip = true;
		// } else {
		// 	this.tooltip_text_line_3 = null;
		// }

		// if (item.orig_item && item.orig_item.question) {
		// 	this.tooltip_text_line_4 = item.orig_item.question;
		// 	this.show_tooltip = true;
		// } else {
		// 	this.tooltip_text_line_4 = null;
		// }

		// if (item.orig_item && item.orig_item.name) {
		// 	this.tooltip_text_line_5 = item.orig_item.name;
		// 	this.show_tooltip = true;
		// } else {
		// 	this.tooltip_text_line_5 = null;
		// }

		// if (item.orig_item && item.orig_item.description) {
		// 	this.tooltip_text_line_6 = item.orig_item.description;
		// 	this.show_tooltip = true;
		// } else {
		// 	this.tooltip_text_line_6 = null;
		// }

		this.tooltip_top = (evt.clientY + window.scrollY) + "px";
		this.tooltip_left = (evt.clientX - 135) + "px";

	}
	mouse_move_item(item: any, loop: any, evt: any): void {
		this.tooltip_top = (evt.clientY + window.scrollY) + "px";
		this.tooltip_left = (evt.clientX - 135) + "px";
	}
	mouse_out_item(item: any, loop: any): void {
		loop.is_active = false;
		this.show_tooltip = false;
	}

	// Returns the orphaned points:
	private test_grid_connectedness(grid_working: Array<Array<boolean>>): Array<any> {

		// needs_processing inverts the grid:
		var needs_processing: Array<Array<boolean>> = [];
		for (var x = 0; x < this.GRID_DIM; x ++) {
			let column = [];
			for (var y = 0; y < this.GRID_DIM; y ++) {
				column.push(!grid_working[x][y]);
			}
			needs_processing.push(column);
		}

		// Assume [0, 0] is still fresh, and start processing from the corner:
		var to_process = [[0, 0]];
		
		while (to_process.length > 0) {

			var duple = to_process.pop();
			var x = duple[0];
			var y = duple[1];

			if (!needs_processing[x][y]) continue;

			needs_processing[x][y] = false;

			if (x > 0 && needs_processing[x - 1][y]) to_process.push([x - 1, y]);
			if (x < this.GRID_DIM - 1 && needs_processing[x + 1][y]) to_process.push([x + 1, y]);
			if (y > 0 && needs_processing[x][y - 1]) to_process.push([x, y - 1]);
			if (y < this.GRID_DIM - 1 && needs_processing[x][y + 1]) to_process.push([x, y + 1]);

		}

		let orphans: Array<any> = [];
		for (var x = 0; x < this.GRID_DIM; x ++) {
			for (var y = 0; y < this.GRID_DIM; y ++) {
				if (needs_processing[x][y]) orphans.push({
					x: x, 
					y: y
				});
			}
		}

		return orphans;

	}

	private find_item(id_ext: number): any {
		for (var module_index = 0; module_index < this.modules.length; module_index ++) {
			let module = this.modules[module_index];
			for (var item_index = 0; item_index < module.assessment_items_flat.length; item_index ++) {
				let item = module.assessment_items_flat[item_index];
				if (item.id_ext == id_ext) return item;
			}
		}

		return null;
	}

	private generate_tag(item: any): string {
		if (item) {
			if (item.retention_icon) return "retention";
			if (item.strategy_icon) return "knight";
			if (item.brain_icon) return "brain";
			if (item.focus_icon) return "wrench";
		}
		return null;
	}

	private create_loop(nodes: Array<any>, min_radius: number, score_ratio: number, name: string): any {

		// nodes is an array of numbers.

		let num_nodes = nodes.length;

		console.log("Attempting to create loop with " + num_nodes + " nodes and score_ratio " + score_ratio + "...");

		// A temporary working copy of the grid:
		let grid_working: Array<Array<boolean>> = [];
		for (var x = 0; x < this.GRID_DIM; x ++) {
			let column = [];
			for (var y = 0; y < this.GRID_DIM; y ++) {
				column.push(this.grid[x][y]);
			}
			grid_working.push(column);
		}

		let red = Math.floor(46.0 + score_ratio * 210.0);
		let green = Math.floor(117.0 + score_ratio * 139.0);
		let blue = Math.floor(182.0 + score_ratio * 74.0);
		let loop = {
			items: [], 
			background_color: "rgb(" + red + "," + green + "," + blue + ")", 
			name: name
		};

		// First find a starting square:
		var current_x, current_y = null;
		for (var radius = min_radius; radius <= this.HALF_GRID_DIM; radius ++) {
			for (var x = this.HALF_GRID_DIM - radius; x <= this.HALF_GRID_DIM + radius; x ++) {
				for (var y = this.HALF_GRID_DIM - radius; y <= this.HALF_GRID_DIM + radius; y ++) {
					if (!grid_working[x][y]) {
						// console.log("Testing grid connectedness for starting square...");
						grid_working[x][y] = true;
						if (this.test_grid_connectedness(grid_working).length == 0) {
							current_x = x;
							current_y = y;
							let item = this.find_item(nodes[0]);
							loop.items.push({
								x: current_x, 
								y: current_y, 
								tag: this.generate_tag(item), 
								orig_item: item
							});
							break;
						} else {
							grid_working[x][y] = false;
							// console.log("Grid connectedness test failed.");
						}
					}
				}
				if (current_x) break;
			}
			if (current_x) break;
		}
		if (current_x) {
			// console.log("Starting square: " + current_x + ", " + current_y);
		} else {
			console.log("Couldn't find starting square!");
			return null;
		}

		for (var node_index = 1; node_index < num_nodes; node_index ++) {

			var node_added = false;

			var directions = this.shuffle([ "E", "N", "W", "S" ]);
			for (var direction_index = 0; direction_index < directions.length; direction_index ++) {

				var test_x, test_y;

				let direction = directions[direction_index];
				if (direction == "E") {
					if (current_x < this.GRID_DIM - 1) {
						test_x = current_x + 1;
						test_y = current_y;
					} else {
						continue;
					}
				} else if (direction == "N") {
					if (current_y > 0) {
						test_x = current_x;
						test_y = current_y - 1;
					} else {
						continue;
					}
				} else if (direction == "W") {
					if (current_x > 0) {
						test_x = current_x - 1;
						test_y = current_y;
					} else {
						continue;
					}
				} else if (direction == "S") {
					if (current_y < this.GRID_DIM - 1) {
						test_x = current_x;
						test_y = current_y + 1;
					} else {
						continue;
					}
				}

				// console.log("Testing direction " + direction + "...");

				// Check if the location has already been taken:
				if (grid_working[test_x][test_y]) {
					// console.log("Square already taken.");
					continue;
				}

				// Check connectedness:
				grid_working[test_x][test_y] = true;
				let orphans = this.test_grid_connectedness(grid_working);
				if (orphans.length < num_nodes - node_index) {
					current_x = test_x;
					current_y = test_y;
					let item = this.find_item(nodes[node_index]);
					loop.items.push({
						x: current_x, 
						y: current_y, 
						tag: this.generate_tag(item), 
						orig_item: item
					});
					orphans.forEach(orphan => {
						node_index ++;
						grid_working[orphan.x][orphan.y] = true;
						let item = this.find_item(nodes[node_index]);
						loop.items.push({
							x: orphan.x, 
							y: orphan.y, 
							tag: this.generate_tag(item), 
							orig_item: item
						});
					});
					node_added = true;
					break;
				} else {
					grid_working[test_x][test_y] = false;
					// console.log("Grid connectedness test failed.");
				}

			}

			if (!node_added) return null;

		}

		// Write to grid:
		loop.items.forEach(item => {
			this.grid[item.x][item.y] = true;
		});

		return loop;

	}

	open_loop(loop: any): void {
		console.log("Attempting to open loop:");
		console.log(loop);
		let ext_ids = [];
		loop.items.forEach(item => {
			if (item.orig_item != null) ext_ids.push(item.orig_item.id_ext);
		});
		var url_suffix = ext_ids.join(",");
		window.open("/#/tree-radial/" + url_suffix);
	}

	private shuffle(array): Array<any> {
		let currentIndex = array.length, randomIndex;

		// While there remain elements to shuffle:
		while (currentIndex != 0) {

			// Pick a remaining element:
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex --;

			// And swap it with the current element:
			[ array[ currentIndex ], array[ randomIndex ] ] = [ array[ randomIndex ], array[ currentIndex ] ];
		}

		return array;
	}

}


