export class Module {
    id: number;
    name: string;
    topics: any;
    is_empty: boolean;
    view_type: string = "grid";

    assessment_items_flat: Array<any> = new Array<any>();

  	constructor(
        id: number, 
        name: string, 
        topics: any, 
        is_empty: boolean, 
    ) {
        this.id = id;
        this.name = name;
        this.topics = topics;
        this.is_empty = is_empty;

        this.topics.forEach(topic => {
            topic.subtopics.forEach(subtopic => {
                subtopic.assessment_items.forEach(assessment_item => {
                    this.assessment_items_flat.push(assessment_item);
                });
            });
        });
    }

}


