
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettings } from '../app-settings';

@Injectable()
export class TestService {

	private psych_model: any;
	private invitation_key: string = null;
	private test_responses: Array<number> = [];
	private context: number = 0;
	private is_saving: boolean = false;

	constructor(
		private httpClient: HttpClient, 
	) { }

	public setInvitationKey(invitation_key: string): void {
		this.invitation_key = invitation_key;
	}
	public getInvitationKey(): string {
		return this.invitation_key;
	}

	public getContext(): number {
		return this.context;
	}

	public setPsychModel(psych_model: any): void {
		this.psych_model = psych_model;
	}
	public getPsychModel(): any {
		return this.psych_model;
	}

	public loadTest(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-test-data", 
			{withCredentials: true}
		).pipe(
			map(res => {
				let success = (res as any).success;
				if (!success) {
					if (res.hasOwnProperty("message")) console.log("Test.Service.loadTest() error: " + (res as any).message);
					return null;
				}
				return res as any;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

	public generateSVNReport(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "generate-svn-report", 
			{withCredentials: true}
		).pipe(
			map(res => {
				let success = (res as any).success;
				if (!success) {
					if (res.hasOwnProperty("message")) console.log("Test.Service.generateSVNReport() error: " + (res as any).message);
					return null;
				}
				return (res as any).report;
			}),
			catchError(error => {
				// alert("An error occurred.");
				return null;
			})
		);
	}

	public generateSVNReportByKey(key: string): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "generate-svn-report-by-key?key=" + key, 
			{withCredentials: true}
		).pipe(
			map(res => {
				let success = (res as any).success;
				if (!success) return null;
				return (res as any).report;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

	public loadTestResponses(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "load-test-responses?invitation_key=" + this.invitation_key, 
			{withCredentials: true}
		).pipe(
			map(res => {
				let success = (res as any).success;
				if (!success) return false;

				let test_responses = (res as any).test_responses;
				if (test_responses != null && test_responses.length > 0) {
					for (var i = 0; i < test_responses.length; i ++) {
						this.test_responses.push(parseInt(test_responses.charAt(i)));
					}
				}

				this.context = (res as any).context

				return this.test_responses;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

	public getTestResponses(): Array<number> {
		return this.test_responses;
	}
	public onResponse(response: number, saveToServer: boolean): void {
		this.test_responses.push(response);
		if (saveToServer) {
			this.saveTestResponses().subscribe(
				res => {
					// Do nothing for now.
				}, 
				error => {
					// Do nothing for now.
				}
			);
		}
	}
	public onFocusResponse(focus_answers: Array<number>): void {
		this.saveFocusResponses(focus_answers).subscribe(
			res => {
				// Do nothing for now.
			}, 
			error => {
				// Do nothing for now.
			}
		);
	}
	public onBack(saveToServer: boolean): void {
		this.test_responses.pop();
		if (saveToServer) {
			this.saveTestResponses().subscribe(
				res => {
					// Do nothing for now.
				}, 
				error => {
					// Do nothing for now.
				}
			);
		}
	}

	public getIsSaving(): boolean {
		return this.is_saving;
	}
	public saveTestResponses(): Observable<any> {
		this.is_saving = true;
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "save-test-responses", 
			JSON.stringify({invitation_key: this.invitation_key, test_responses: this.test_responses.join(""), timestamp: new Date().getTime()}), 
			{headers: AppSettings.POST_HEADERS}
		).pipe(
			map(res => {
				this.is_saving = false;
				return null;
			}),
			catchError(error => {
				// alert("An error occurred.");
				this.is_saving = false;
				return null;
			})
		);
	}
	public saveFocusResponses(focus_answers: Array<number>): Observable<any> {
		this.is_saving = true;
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "save-focus-responses", 
			JSON.stringify({invitation_key: this.invitation_key, focus_answers: focus_answers, timestamp: new Date().getTime()}), 
			{headers: AppSettings.POST_HEADERS}
		).pipe(
			map(res => {
				this.is_saving = false;
				return null;
			}),
			catchError(error => {
				// alert("An error occurred.");
				this.is_saving = false;
				return null;
			})
		);
	}

}


