import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettings } from '../app-settings';

@Injectable()
export class PurchaseService {

	constructor(
		private httpClient: HttpClient, 
		private router: Router
	) { }

	completePurchase(token: any): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "complete-purchase", 
			JSON.stringify({token: token}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return res;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

}


