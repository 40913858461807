export class TreeUtils {

    public static WIDTH = 800;
    public static HEIGHT_PER_LEAF = 20;
    public static ANIMATION_DURATION = 750;

    public static OVERLAY_HORIZONTAL_OFFSET = 15;
    public static OVERLAY_VERTICAL_OFFSET = 15;

    public static MAX_POINTS_PER_ITEM = 10;
    public static POINT_CIRCLE_RADIUS = 7;

    public static TEXT_OFFSET_RECT_FILL_COLOR = "rgba(255,255,255,0.8)";

    public static count_leaves(node): number {
        var count = 0;
        if (node.children) {
            node.children.forEach(function(child) {
                count += TreeUtils.count_leaves(child);
            });
            return count;
        } else {
            return 1;
        }
    }
    public static count_possible_leaves(node): number {
        var count = 0;
        if (node._children) {
            node._children.forEach(function(child) {
                count += TreeUtils.count_possible_leaves(child);
            });
            return count;
        } else {
            return 1;
        }
    }

    public static compute_text_x_for_datum(d: any, is_scorecard_open: boolean): number {
        if (d.num_potential_points) return 10 + 16 * d.num_potential_points;
        if (d.depth == 1) return is_scorecard_open ? -13 : 13;
        return d.children || d._children ? -13 : 13;
    }

    public static compute_text_anchor_for_datum(d: any, is_scorecard_open: boolean): string {
        if (d.depth == 1) return is_scorecard_open ? "end" : "start";
        return d.children || d._children ? "end" : "start";
    }

    public static compute_text_offset_rect_y_for_datum(d: any, is_scorecard_open: boolean): any {
        if (d.depth == 1 && !is_scorecard_open) return -5000;
        return "-.35em";
    }

}


