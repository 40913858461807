export class Rating {
    id: number;
    user_id: number;
    rater_id: number;
    rating: number;
    course_id: number;
    assessment_item_id: number;
    date: Date;

    // For graphing only:
    x: Date;
    y: number;

    constructor(
        id: number, 
        user_id: number, 
        rater_id: number, 
        rating: number, 
        course_id: number, 
        assessment_item_id: number, 
        date: Date, 
    ) {
        this.id = id;
        this.user_id = user_id;
        this.rater_id = rater_id;
        this.rating = rating;
        this.course_id = course_id;
        this.assessment_item_id = assessment_item_id;
        this.date = new Date(date);
    }

}


