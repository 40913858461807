import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettings } from '../app-settings';

import { Team } from '../models/team';
import { CourseSubscription } from '../models/course-subscription';
import { TeamMembership } from '../models/team-membership';

@Injectable()
export class TeamService {

	constructor(
		private httpClient: HttpClient, 
		private router: Router
	) { }

	createTeamObject(src: any): Team {
		if (src) {
			let team = new Team(
					src.id, 
					src.user_id, 
					src.name,
				);
			if (src.hasOwnProperty("memberships")) {
				for (var i = 0; i < src.memberships.length; i ++) {
					let membership = src.memberships[i];
					team.memberships.push(new TeamMembership(
						membership.id, 
						membership.team_id, 
						membership.user_id, 
						membership.member_fname, 
						membership.member_lname, 
						membership.member_name, 
						membership.member_thumbnail_filename, 
						membership.member_phone, 
						membership.hasOwnProperty("available") ? membership.available : false, 
						membership.hasOwnProperty("call_availability") ? membership.call_availability : null, 
						membership.hasOwnProperty("video") ? membership.video : null, 
					));
				}
				team.memberships.sort(function(a, b) {
				    if (a.member_lname < b.member_lname) { return -1; }
				    if (a.member_lname > b.member_lname) { return 1; }
				    return 0;
				});
			}
			if (src.hasOwnProperty("course_subscriptions")) {
				for (var i = 0; i < src.course_subscriptions.length; i ++) {
					team.course_subscriptions.push(new CourseSubscription(src.course_subscriptions[i]));
				}
			}
			return team;
		} else {
			return new Team(
					-1, 
					-1, 
					"",
				);
		}
	}

	createCourseSubscription(team_id: number, course_id: number): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "create-course-subscription", 
			JSON.stringify({team_id: team_id, course_id: course_id}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return this.extractData_createCourseSubscription(res);
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}
	private extractData_createCourseSubscription(res: any): CourseSubscription {
		if (res.success) {
			return new CourseSubscription(res.subscription);
		} else {
			// alert(res.message);
			return null;
		}
	}

	deleteCourseSubscription(subscription: CourseSubscription): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "delete-course-subscription", 
			JSON.stringify({id: subscription.id}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return null;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

	createTeam(name: string): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "create-team", 
			JSON.stringify({name: name}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return this.extractData_createTeam(res);
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}
	private extractData_createTeam(res: any): Team {
		if (res.success) {
			return this.createTeamObject(res.team);
		} else {
			// alert(res.message);
			return null;
		}
	}

	editTeam(team: Team): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "edit-team", 
			JSON.stringify({team: team}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return this.extractData_editTeam(res);
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}
	private extractData_editTeam(res: any): Team {
		if (res.success) {
			return this.createTeamObject(res.team);
		} else {
			// alert(res.message);
			return null;
		}
	}

	addOrInviteMemberByEmail(team_id: number, member_email: string): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "add-or-invite-team-member-by-email", 
			JSON.stringify({team_id: team_id, member_email: member_email}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return this.extractData_addOrInviteMemberByEmail(res);
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}
	private extractData_addOrInviteMemberByEmail(res: any): TeamMembership {
		if (res.success) {
			if (res.membership == null) return null; // Interpret as an invitation being sent.
			return new TeamMembership(
				res.membership.id, 
				res.membership.team_id, 
				res.membership.user_id, 
				res.membership.member_fname, 
				res.membership.member_lname, 
				res.membership.member_name, 
				res.membership.member_thumbnail_filename, 
				res.membership.member_phone, 
				res.membership.hasOwnProperty("available") ? res.membership.available : false, 
				res.membership.hasOwnProperty("call_availability") ? res.membership.call_availability : null, 
				res.membership.hasOwnProperty("video") ? res.membership.video : null, 
			);
		} else {
			alert(res.message);
			return null;
		}
	}

	deleteMembership(membership: TeamMembership): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "delete-membership", 
			JSON.stringify({id: membership.id}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return null;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

	deleteTeam(team: Team): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "delete-team", 
			JSON.stringify({id: team.id}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return null;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

	getTeamAsCoach(team_id: number): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-team-as-coach?id=" + team_id, 
			{withCredentials: true}
		).pipe(
			map(res => {
				return this.extractData_getTeamAsCoach(res);
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}
	private extractData_getTeamAsCoach(res: any): Team {
		if (res.success) {
			return this.createTeamObject(res.team);
		} else {
			// alert(res.message);
			return null;
		}
	}

	getTeamsAsCoach(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-teams-as-coach", 
			{withCredentials: true}
		).pipe(
			map(res => {
				return this.extractData_getTeamsAsCoach(res);
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}
	private extractData_getTeamsAsCoach(res: any): Array<Team> {
		if (res.success) {
			var teams = new Array<Team>();
			for (var i = 0; i < res.teams.length; i ++) {
				teams.push(this.createTeamObject(res.teams[i]));
			}
			return teams;
		} else {
			// alert(res.message);
			return null;
		}
	}

}


