import { 
	Component, 
} from '@angular/core';


@Component({
	selector: 'invitation-key-missing',
	templateUrl: './invitation-key-missing.component.html',
	styleUrls: ['./invitation-key-missing.component.css']
})
export class InvitationKeyMissingComponent {
	constructor() {}

}


