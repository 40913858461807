import { 
	Component, 
	OnInit
} from '@angular/core';

import { Router } from '@angular/router'

@Component({
	selector: 'test-intro',
	templateUrl: './test-intro.component.html',
	styleUrls: ['./test-intro.component.css']
})
export class TestIntroComponent implements OnInit {

	constructor(
		private router: Router
	) {
		// Do nothing.
	}

	ngOnInit(): void {
		// Nothing for now.
	}

	private takeTest(): void {
		this.router.navigate(["test"]);
	}

}


