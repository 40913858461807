export class Task {
    id: number;
    module_id: number;
    user_id: number;
    text: string;
    uid: number; // for drag-and-drop UI only
    is_complete: boolean;

  	constructor(
        id: number, 
        module_id: number, 
        user_id: number, 
        text: string, 
        uid: number, 
        is_complete: boolean, 
    ) {
        this.id = id;
        this.module_id = module_id;
        this.user_id = user_id;
        this.text = text;
        this.uid = uid;
        is_complete = is_complete;
    }

}


