import { 
	Component, 
	OnInit
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router'

import { TestService } from '../services/test.service';
import { UserService } from '../services/user.service';

@Component({
	selector: 'welcome',
	templateUrl: './welcome.component.html',
	styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

	private test_invitation_key: string = null;

	private ui_hack: boolean = false;

	constructor(
		private route: ActivatedRoute, 
		private router: Router, 
		private testService: TestService, 
		private userService: UserService, 
	) {
		this.route.queryParams.subscribe(params => {
			// Team invitation:
	        if (params["invitation"]) this.router.navigate(["sign-up/" + params["invitation"]]);

	        // Test invitation:
	        this.test_invitation_key = params["key"];
	    });
	}

	ngOnInit(): void {
		if (this.test_invitation_key) {
			this.testService.setInvitationKey(this.test_invitation_key);
			this.testService.loadTestResponses().subscribe(
				test_responses => {
					if (test_responses === false) {
						this.router.navigate(["invitation-key-missing"]);
					} else if (test_responses.length == 0) {
						this.router.navigate(["test-intro"]);
					} else {
						this.router.navigate(["test"]);
					}
				}, 
				error => {
					// Do nothing for now.
				}
			);
		}

		setTimeout(() => { this.ui_hack = true; }, 5000);
	}

}


