import { 
	Component, 
	ElementRef, 
	HostListener, 
	OnInit, 
	ViewChild, 
} from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router"

import * as d3 from "d3";

import { AppSettings } from "../app-settings";
import { TreeUtils } from "../utils/TreeUtils";

import { AppComponentService } from "../services/appcomponent.service";
import { ModuleService } from "../services/module.service";
import { UserService } from "../services/user.service";

@Component({
	selector: "tree-radial",
	templateUrl: "./tree-radial.component.html",
	styleUrls: ["./tree-radial.component.css"]
})
export class TreeRadialComponent implements OnInit {

	private APP_SETTINGS = AppSettings;

	private tree_data = {name: "", children: []};

	private show_tooltip: boolean = false;
	private tooltip_text_line_0: string = null;
	private tooltip_text_line_1: string = null;
	private tooltip_text_line_2: string = null;
	private tooltip_text_line_3: string = null;
	private tooltip_text_line_4: string = null;
	private tooltip_text_line_5: string = null;
	private tooltip_text_line_6: string = null;
	private tooltip_top: string = null;
	private tooltip_left: string = null;
	private tooltip_item: any = null;

	private show_slider_overlay: boolean = false;
	private slider_overlay_left: string = null;
	private slider_overlay_top: string = null;
	private active_d3_datum: any;

	// private active_assessment_item_name: string = null;
	// private active_assessment_item_description: string = null;
	private rating_box_line_0: string = null;
	private rating_box_line_1: string = null;
	private rating_box_line_2: string = null;
	private rating_box_line_3: string = null;

	private active_assessment_item_rating: any = null;

	private active_item_text: any = null;

	private num_potential_points: number = 0;
	private num_points: number = 0;

	@ViewChild("tree_container", {static: true}) tree_container: ElementRef;

	constructor(
		private app_component_service: AppComponentService, 
		private route: ActivatedRoute, 
		private router: Router, 
		private module_service: ModuleService, 
		private user_service: UserService
	) {
		if (this.user_service.getLoggedInUser() == null) {
			this.router.navigate(["/"]);
			return;
		}
	}

	ngOnInit(): void {
		this.module_service.get_modules_global().subscribe(
			res => {
				for (var i = 0; i < res.modules.length; i ++) {
					if (res.modules[i].d3_tree) this.tree_data.children.push({name: res.modules[i].name, children: res.modules[i].d3_tree.children, rating: res.modules[i].d3_tree.rating})
				}
				this.generate_tree();
			}, 
			error => {
				// Do nothing.
			}
		);
	}

	private ignore_window_click: boolean = false;
	@HostListener("window:click", ["$event.target"]) 
    private on_click(target) {
    	if (this.ignore_window_click) {
    		this.ignore_window_click = false;
    		return;
    	}
    	if (typeof target.className != "string" || (target.className.indexOf("slider_button") == -1 && target.className.indexOf("slider_button_filling") == -1 && target.className.indexOf("game_mark") == -1)) {
    		if (this.active_item_text) this.active_item_text.setAttribute("fill", "black");
			this.show_slider_overlay = false;
    	}
    }

	private _expand_all: any;
	private expand_all(): void {
		this._expand_all();
	}
	private _collapse_all: any;
	private collapse_all(): void {
		this._collapse_all();
	}

	private points_container_active: boolean = false;
	private on_rated(rating: number) {

		this.active_d3_datum.data.rating = rating;
		(document.getElementById("node_" + (this.active_d3_datum.id)).children[0] as any).style.fill = AppSettings.getColorForRating(rating);

		var el = this.active_d3_datum.parent;
		while (el.depth > 0) {

			var num_ratings = 0;
			var sum_ratings = 0;
			el.children.forEach(child => {
				if (child.data.rating) {
					num_ratings ++;
					sum_ratings += child.data.rating;
				}
			});
			let ave_rating = sum_ratings / num_ratings;
			el.data.rating = ave_rating;

			(document.getElementById("node_" + (el.id)).children[0] as any).style.fill = AppSettings.getColorForRating(ave_rating);

			el = el.parent;
		}

		if (rating == 10 && this.active_d3_datum.hasOwnProperty("num_potential_points") && this.active_d3_datum.num_potential_points > 0) {
			this.num_points += this.active_d3_datum.num_potential_points;

			var t = d3.transition()
				    .duration(1000)
				    .ease(d3.easeLinear);
			for (var i = 0; i < this.active_d3_datum.num_potential_points; i ++) {
				d3.select("#potential_point_" + this.active_d3_datum.id + "_" + i).style("fill", "gold");
				d3.selectAll("#potential_point_" + this.active_d3_datum.id + "_" + i).transition(t).style("opacity", 0);
			}

			let _this = this;
			setTimeout(function() {
				_this.active_d3_datum.num_potential_points = 0;
				_this.update_node(_this.active_d3_datum);

				for (var i = 0; i < _this.active_d3_datum.num_potential_points; i ++) {
					d3.select("#potential_point_" + _this.active_d3_datum.id + "_" + i).style("fill", "url(#svn_pattern)");
					d3.selectAll("#potential_point_" + _this.active_d3_datum.id + "_" + i).transition(t).style("opacity", 1);
				}
			}, 1000);
			
			this.points_container_active = true;
			setTimeout(function() { _this.points_container_active = false; }, 1000);
		}

	}

	private update_node: any = null;
	private generate_tree(): void {

		let _this = this;

		let root = d3.hierarchy(this.tree_data, function(d) {return d.children;});
		// Open the first level:
		root._children = root.children;
		// Collapse after the second level:
		root.children.forEach(collapse);

		this.route.params.subscribe(params => {
			if (params.hasOwnProperty("loop_items")) {
				let loop_items = [];
				let loop_items_raw = params.loop_items.split(",");
				for (var i = 0; i < loop_items_raw.length; i ++) {
					loop_items.push(parseInt(loop_items_raw[i]));
				}
				root.children.forEach(child => {
					expand_if(child, loop_items);
				});
			}
		});

		let num_possible_leaves = TreeUtils.count_possible_leaves(root);
		var vert_scale = Math.round(TreeUtils.count_leaves(root) / num_possible_leaves * 100) / 100;

		let PLOT_WIDTH = TreeUtils.WIDTH;
		let PLOT_HEIGHT = TreeUtils.HEIGHT_PER_LEAF * num_possible_leaves;
		let MARGIN = {top: 0, right: 0, bottom: 0, left: 0};

		const svg = d3.create("svg").attr("viewBox", [0, 0, PLOT_WIDTH + MARGIN.left + MARGIN.right, PLOT_HEIGHT + MARGIN.top + MARGIN.bottom]);

		const defs = svg.append("defs");
		const svn_pattern = defs.append("pattern")
			    .attr("id", "svn_pattern")
			    .attr("height", "100%")
			    .attr("width", "100%")
			    .attr("viewBox", "0 0 7 7");
		const svn_image = svn_pattern.append("image")
				.attr("x", 0)
				.attr("y", 0)
				.attr("height", 7)
				.attr("width", 7)
				.attr("xlink:href", "/assets/SVN_logo_piece.png");

		svg.append("g").attr("transform", "translate("+ MARGIN.left + "," + MARGIN.top + ")");

		var node_id = 0;
		
		let treemap = d3.tree().size([PLOT_HEIGHT, PLOT_WIDTH]);

		update(root);

		function expand_if(d, loop_items) {

			var to_return = false;
			if (d.data.hasOwnProperty("item") && d.data.item != null && loop_items.indexOf(d.data.item.id_ext) > -1) {
				d.data.is_in_loop = true;
				to_return = true;
			}

			if (d._children) {
				var open_children = false;
				for (var i = 0; i < d._children.length; i ++) {
					if (expand_if(d._children[i], loop_items)) open_children = true;
				}
				if (open_children) {
					d.children = d._children;
					d._children = null;
					return true;
				}
			} else if (d.children) {
				for (var i = 0; i < d.children.length; i ++) {
					expand_if(d.children[i], loop_items)
				}
			}

			return to_return;
		}
		function expand(d) {
			if (d._children) {
				d.children = d._children;
				d._children.forEach(expand);
				d._children = null;
			} else if (d.children) {
				d.children.forEach(expand);
				d._children = null;
			}
		}
		function collapse(d) {
			if (d.children) {
				d._children = d.children;
				d._children.forEach(collapse);
				d.children = null;
			} else if (d._children) {
				d._children.forEach(collapse);
				d.children = null;
			}
		}

		function count_children(d) {
			var num_children = 0;
			if (d.children) {
				num_children += d.children.length;
				d.children.forEach(function(d2) {
					num_children += count_children(d2)
				});
			}
			if (d._children) {
				num_children += d._children.length;
				d._children.forEach(function(d2) {
					num_children += count_children(d2)
				});
			}
			return num_children;
		}

		_this._expand_all = function() {
			expand(root);
			update(root);
		}
		_this._collapse_all = function() {
			root.children.forEach(collapse);
			update(root);
		}

		var ignore_tooltip = false;

		function update(source) {

			let num_leaves = TreeUtils.count_leaves(root);

			// Assigns the x and y position for the nodes:
			var treeData = treemap(root);

			// Compute the new tree layout:
			var nodes = treeData.descendants(), links = treeData.descendants().slice(1);

			// Normalize for fixed-depth: 
			nodes.forEach(function(d) { d.y = d.depth * 50 + 150; });

			// ****************** NODES ***************************

			var node = svg.selectAll("g.node").data(nodes, function(d) { return d.id || (d.id = ++node_id); });

			// Enter any new nodes at the parent's previous position:
			var nodeEnter = node.enter().append("g")
					.attr("id", function(d) {
						return "node_" + d.id;
					})
					.attr("class", "node")
					.attr("transform", function(d) {
						return "translate(" + (source.y0 ? source.y0 : 0) + "," + ((source.x0 ? source.x0 : 0) * vert_scale) + ")";
					})
					.on("click", on_node_click)
					.on("mouseover", function(d) {

						if (ignore_tooltip) return;
						// if (d.data.hasOwnProperty("children") && d.data.children.length > 0) return;
						_this.show_tooltip = false;

						if (d.data.hasOwnProperty("item") && d.data.item && d.data.item.hasOwnProperty("category") && d.data.item.category) {
							_this.tooltip_text_line_0 = d.data.item.category;
							_this.show_tooltip = true;
						} else {
							_this.tooltip_text_line_0 = null;
						}

						if (d.data.hasOwnProperty("item") && d.data.item && d.data.item.hasOwnProperty("scorecard") && d.data.item.scorecard) {
							_this.tooltip_text_line_1 = d.data.item.scorecard;
							_this.show_tooltip = true;
						} else {
							_this.tooltip_text_line_1 = null;
						}

						if (d.data.hasOwnProperty("item") && d.data.item && d.data.item.hasOwnProperty("partner") && d.data.item.partner) {
							_this.tooltip_text_line_2 = d.data.item.partner;
							_this.show_tooltip = true;
						} else {
							_this.tooltip_text_line_2 = null;
						}

						if (d.data.hasOwnProperty("item") && d.data.item && d.data.item.hasOwnProperty("notes") && d.data.item.notes) {
							_this.tooltip_text_line_3 = d.data.item.notes;
							_this.show_tooltip = true;
						} else {
							_this.tooltip_text_line_3 = null;
						}

						if (d.data.hasOwnProperty("item") && d.data.item && d.data.item.hasOwnProperty("question") && d.data.item.question) {
							_this.tooltip_text_line_4 = d.data.item.question;
							_this.show_tooltip = true;
						} else {
							_this.tooltip_text_line_4 = null;
						}

						if (d.data.hasOwnProperty("item") && d.data.item && d.data.item.hasOwnProperty("name") && d.data.item.name) {
							_this.tooltip_text_line_5 = d.data.item.name;
							_this.show_tooltip = true;
						} else {
							_this.tooltip_text_line_5 = null;
						}

						if (d.data.hasOwnProperty("item") && d.data.item && d.data.item.hasOwnProperty("description") && d.data.item.description) {
							_this.tooltip_text_line_6 = d.data.item.description;
							_this.show_tooltip = true;
						} else {
							_this.tooltip_text_line_6 = null;
						}

						if (d.data.hasOwnProperty("item")) {
							_this.tooltip_item = d.data.item;
						} else {
							_this.tooltip_item = null;
						}

					})
					.on("mousemove", function(d) {
						_this.tooltip_top = d3.event.pageY - document.getElementById("main_content_wrapper").offsetTop + TreeUtils.OVERLAY_VERTICAL_OFFSET + "px";
						_this.tooltip_left = d3.event.pageX - document.getElementById("main_content_wrapper").offsetLeft + TreeUtils.OVERLAY_HORIZONTAL_OFFSET + "px";
					})
					.on("mouseout", function(d) {
						_this.show_tooltip = false;
					});

			// Add node circles:
			nodeEnter.append("circle")
					.attr("class", "node")
					.attr("r", 1e-6)
					.attr("cursor", "pointer")
					.attr("data", function(d) {
						if (d.data.item && d.data.item.id_ext) {
							return d.data.item.id_ext;
						} else {
							return "";
						}
					})
					.style("fill", function(d) {
						if (d.data.rating) return AppSettings.getColorForRating(d.data.rating);
						return d._children ? AppSettings.SVN_BLUE : "black";
					});
			// Add tag circles:
			nodeEnter.append("circle")
					.attr("id", function(d) {
						return "tag_" + d.id;
					})
					.attr("class", "tag")
					.attr("r", 1e-6)
					.style("stroke", function(d) {
						// return d.data.tagged ? "white" : "none";
						return d.data.is_in_loop ? "gold" : "none";
					})
					.style("stroke-width", "4")
					.style("fill", "rgba(0,0,0,0)")
					.style("cursor", "pointer");

			// // Add potential points:
			// for (var i = 0; i < TreeUtils.MAX_POINTS_PER_ITEM; i ++) {
			// 	nodeEnter.append("circle")
			// 			.attr("id", function(d) {
			// 				return "potential_point_" + d.id + "_" + i;
			// 			})
			// 			.attr("class", "potential_point potential_point_" + i)
			// 			.attr("r", 1e-6)
			// 			.attr("cx", function(d) {
			// 				return 16 * (i + 1)
			// 			})
			// 			.style("stroke-width", "2")
			// 			.style("fill", "url(#svn_pattern)");
			// }

			// setTimeout(function() {
			// 	nodeEnter.insert("rect", "text")
			// 		    .attr("x", function(d) {
			// 		    	return (this.parentNode.getBBox().width + 5) * -1;
			// 		    })
			// 		    .attr("y", function(d) {
			// 		    	return TreeUtils.compute_text_offset_rect_y_for_datum(d, false);
			// 		    })
			// 		    .attr("width", function(d) {
			// 		    	return d.depth == 1 ? this.parentNode.getBBox().width : 0;
			// 		    })
			// 		    .attr("height", function(d) {
			// 		    	return d.depth == 1 ? this.parentNode.getBBox().height : 0;
			// 		    })
			// 		    .style("fill", TreeUtils.TEXT_OFFSET_RECT_FILL_COLOR);
			// }, 0);

			var nodeUpdate = nodeEnter.merge(node);

			var link = svg.selectAll("path.link").data(links, function(d) { return d.id; });

			// Enter any new links at the parent's previous position:
			var linkEnter = link.enter().insert("path", "g")
					.attr("class", "link")
					.attr("d", function(d) {
						var o = {x: source.x0, y: source.y0}
						return diagonal(o, o)
					})
					.style("fill", "none")
					.style("stroke", AppSettings.SVN_BLUE)
					.style("stroke-width", "1");

			var linkUpdate = linkEnter.merge(link);

			// Have to adjust vert_scale here to get the animation right:
			// vert_scale = Math.round(num_leaves / num_possible_leaves * 100) / 100;
			vert_scale = 1.0;

			// Wrap around:
			var min_x = 0, max_x = 0, min_y = 0, max_y = 0, max_depth = 0;
			nodes.forEach(function(d) { 
				if (d.x > max_x) max_x = d.x;
				if (d.x < min_x) min_x = d.x;
				if (d.y > max_y) max_y = d.y;
				if (d.y < min_y) min_y = d.y;
				if (d.depth > max_depth) max_depth = d.depth;
			});
			let center_x = PLOT_WIDTH / 2;
			let center_y = PLOT_WIDTH / 2;
			let max_radius = center_x - 100;
			nodes.forEach(function(d) {

				var radius;
				var angle;

				if (max_depth == 0) {
					radius = 0;
					angle = 0;
				} else {
					radius = max_radius * d.depth / max_depth;
					angle = 2 * Math.PI * (d.x / max_x);
				}

				// Remember to invert:
				d.x = center_x + Math.sin(angle) * radius;
				d.y = center_y + Math.cos(angle) * radius;
			});

			// Add labels:
			nodeEnter.append("text")
					.attr("dy", ".35em")
					.attr("dx", function(d) {
						if (d.y > PLOT_WIDTH / 2) {
							return "1em";
						} else {
							return "-1em";
						}
					})
					.attr("text-anchor", function(d) {
						if (d.y > PLOT_WIDTH / 2) {
							return "start";
						} else {
							return "end";
						}
					})
					.attr("fill", "black")
					.attr("stroke", "none")
					.style("font-size", "8px")
					.text(function(d) {
						return "";
						if (d.depth > 1) return "";
						if (d.depth != 1 && ((d._children && d._children.length > 0) || (d.children && d.children.length > 0))) return "";
						if (d.data.name) return d.data.name;
						if (d.data.item && d.data.item.question) return d.data.item.question;
						if (d.data.id_str) return d.data.id_str;
						return ""; 
					})
					.attr("cursor", "pointer");
			// setTimeout(function() {
			// 	nodeEnter.insert("rect", "text")
			// 			.attr("class", "text_background")
			// 		    .attr("x", function(d) {
			// 		    	if (d.y > PLOT_WIDTH / 2) {
			// 					return 5;
			// 				} else {
			// 					return (this.parentNode.getBBox().width + 5) * -1;
			// 				}
			// 		    })
			// 		    .attr("y", function(d) {
			// 		    	return "-.35em";
			// 		    })
			// 		    .attr("width", function(d) {
			// 		    	return d.depth == 1 ? this.parentNode.getBBox().width : 0;
			// 		    })
			// 		    .attr("height", function(d) {
			// 		    	return d.depth == 1 ? this.parentNode.getBBox().height : 0;
			// 		    })
			// 		    .style("fill", TreeUtils.TEXT_OFFSET_RECT_FILL_COLOR);
			// }, 0);

			// Transition to the proper position for the node:
			nodeUpdate.transition()
					.duration(TreeUtils.ANIMATION_DURATION)
					.attr("transform", function(d) { 
						return "translate(" + d.y + "," + (d.x * vert_scale) + ")";
					});

			// Update the node attributes and style:
			nodeUpdate.select("circle.node")
					.attr("r", function(d) {
						// return 5 + (5 - d.depth);
						return 5;
					})
					.style("fill", function(d) {
						if (d.data.rating) return AppSettings.getColorForRating(d.data.rating);
					    return d._children ? AppSettings.SVN_BLUE : "black";
					});
			nodeUpdate.select("circle.tag")
					.attr("r", 7);
			for (var i = 0; i < TreeUtils.MAX_POINTS_PER_ITEM; i ++) {
				nodeUpdate.select("circle.potential_point_" + i)
						.style("stroke", function(d) {
							return (!d.hasOwnProperty("num_potential_points") || d.num_potential_points <= i) ? "transparent" : "gold";
						})
						.attr("r", function(d) {
							return (!d.hasOwnProperty("num_potential_points") || d.num_potential_points <= i) ? 1e-6 : 7;
						});
			}

			// Remove any exiting nodes:
			var nodeExit = node.exit().transition()
					.duration(TreeUtils.ANIMATION_DURATION)
					.attr("transform", function(d) {
						return "translate(" + source.y + "," + (source.x * vert_scale) + ")";
					})
					.remove();

			// On exit reduce the circles size to 0:
			nodeExit.select("circle").attr("r", 1e-6);
			nodeExit.selectAll("circle.potential_point").attr("r", 1e-6);

			// ****************** LINKS ***************************

			// Transition back to the parent element position:
			linkUpdate.transition()
					.duration(TreeUtils.ANIMATION_DURATION)
					.attr("d", function(d) { return diagonal(d, d.parent) });

			// Remove any exiting links:
			var linkExit = link.exit().transition()
					.duration(TreeUtils.ANIMATION_DURATION)
					.attr("d", function(d) {
						var o = {x: source.x, y: source.y}
						return diagonal(o, o)
					})
					.remove();

			// Store the old positions for transition:
			nodes.forEach(function(d) {
				d.x0 = d.x;
				d.y0 = d.y;
			});

			// Creates a curved (diagonal) path from parent to the child nodes:
			function diagonal(s, d) {
				let path = `M ${s.y} ${(s.x * vert_scale)}
				        C ${(s.y + d.y) / 2} ${(s.x * vert_scale)},
				          ${(s.y + d.y) / 2} ${(d.x * vert_scale)},
				          ${d.y} ${(d.x * vert_scale)}`;
				return path;
			}

			// Toggle children on click:
			function on_node_click(d) {
				if (d.data.hasOwnProperty("item") && d.data.item && (!d.children || d.children.length == 0) && (!d._children || d._children.length == 0)) {
					// _this.show_slider_overlay = true;
					_this.slider_overlay_top = d3.event.pageY - document.getElementById("main_content_wrapper").offsetTop + TreeUtils.OVERLAY_VERTICAL_OFFSET + "px";
					_this.slider_overlay_left = d3.event.pageX - document.getElementById("main_content_wrapper").offsetLeft + TreeUtils.OVERLAY_HORIZONTAL_OFFSET + "px";
					_this.active_d3_datum = d;

					if (d.data.item.hasOwnProperty("category") && d.data.item.category.length > 0) {
						_this.rating_box_line_0 = d.data.item.category;
						_this.show_slider_overlay = true;
					} else {
						_this.rating_box_line_0 = null;
					}

					if (d.data.item.hasOwnProperty("question") && d.data.item.question.length > 0) {
						_this.rating_box_line_1 = d.data.item.question;
						_this.show_slider_overlay = true;
					} else {
						_this.rating_box_line_1 = null;
					}

					if (d.data.item.hasOwnProperty("name") && d.data.item.name.length > 0) {
						_this.rating_box_line_2 = d.data.item.name;
						_this.show_slider_overlay = true;
					} else {
						_this.rating_box_line_2 = null;
					}

					if (d.data.item.hasOwnProperty("description") && d.data.item.description.length > 0) {
						_this.rating_box_line_3 = d.data.item.description;
						_this.show_slider_overlay = true;
					} else {
						_this.rating_box_line_3 = null;
					}

					_this.active_assessment_item_rating = (d.data.hasOwnProperty("rating") && d.data.rating) ? d.data.rating : -1;

					if (_this.active_item_text) _this.active_item_text.setAttribute("fill", "black");
					let node = document.getElementById("node_" + d.id);
					if (node.children.length >= 4) {
						_this.active_item_text = document.getElementById("node_" + d.id).children[3];
						_this.active_item_text.setAttribute("fill", AppSettings.SVN_BLUE);
					} else {
						_this.active_item_text = null;
					}
					_this.ignore_window_click = true;
				} else {
					_this.show_slider_overlay = false;
				}

				if (d.children) {
					d._children = d.children;
					d.children = null;
				} else {
					d.children = d._children;
					d._children = null;
				}

				ignore_tooltip = true;
				setTimeout(function() { ignore_tooltip = false; }, TreeUtils.ANIMATION_DURATION);

				update(d);
			}

		}

		_this.update_node = update;

		_this.tree_container.nativeElement.append(svg.node());

	}

}


