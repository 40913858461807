import { 
	Component, 
	OnInit
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router'

import { TestService } from '../services/test.service';
import { UserService } from '../services/user.service';

@Component({
	selector: 'report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

	// https://dev.customskills.com/#/report?key=8E98M1O52WE06DMSL67Z

	private key: string = null;
	private report: any = null;

	private step: number = 0;

	constructor(
		private route: ActivatedRoute, 
		private router: Router, 
		private testService: TestService, 
		private userService: UserService, 
	) {
		this.route.queryParams.subscribe(params => {
	        this.key = params["key"];
	    });
	}

	ngOnInit(): void {
		if (this.key) {
			this.testService.generateSVNReportByKey(this.key).subscribe(
				report => {
					this.report = report;
					// console.log(report);
				}, 
				error => {
					// Do nothing for now.
				}
			);
		}
	}

}


