import { 
	Component, 
	ElementRef, 
	OnDestroy, 
	OnInit, 
	QueryList, 
	ViewChild, 
	ViewChildren, 
} from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router"

import { FileSelectDirective, FileUploader } from 'ng2-file-upload/ng2-file-upload';

import { AppSettings } from "../app-settings";

import { CourseComponent } from "../course/course.component";

import { AppComponentService } from "../services/appcomponent.service";
import { CourseService } from "../services/course.service";
import { RatingService } from "../services/rating.service";
import { UserService } from "../services/user.service";

import { Course } from "../models/course";
import { Rating } from "../models/rating";
import { Team } from "../models/team";
import { TeamMembership } from "../models/team-membership";

@Component({
	selector: "do-course",
	templateUrl: "./do-course.component.html",
	styleUrls: ["./do-course.component.css"]
})
export class DoCourseComponent implements OnDestroy, OnInit {

	private APP_SETTINGS = AppSettings;

	private course: Course;
	private isCourseDisplayed: boolean = false;

	private team: Team;
	private isTeamDisplayed: boolean = false;
	private activeMembership: TeamMembership;
	private show_user_video: boolean = false;

	private video: string = null;

	private ratingHistory: Array<Rating>;
	private isRatingHistoryDisplayed: boolean = false;

	private is_leaderboard_displayed: boolean = false;

	private refreshInterval: number = -1;

	@ViewChildren("courseComponent") courseComponents: QueryList<CourseComponent>;

	@ViewChild("fileInput_video", {static: true}) fileInput_video: ElementRef;

	private uploadInterval_video: any;
	public uploadProgress_video: number = 0;

	private uploader_video: FileUploader = new FileUploader({
		url: AppSettings.API_ENDPOINT + "upload-user-video", 
		autoUpload: true, 
	});

	constructor(
		private appComponentService: AppComponentService, 
		private ratingService: RatingService, 
		private route: ActivatedRoute, 
		private router: Router, 
		private courseService: CourseService, 
		private userService: UserService
	) {
		if (this.userService.getLoggedInUser() == null) {
			this.router.navigate(["/"]);
			return;
		}
	}

	ngOnInit(): void {
		this.loadCourse(false);

		this.appComponentService.getAppComponent().setDoCourseComponent(this);

		var _this = this;
		this.refreshInterval = window.setInterval(function() {
			_this.loadCourse(true);
		}, 10000);

		this.uploadInterval_video = setInterval(() => {
			if (this.uploader_video.queue.length) {
				this.uploadProgress_video = this.uploader_video.queue[0].progress;
			} else {
				this.uploadProgress_video = 0;
			}
	    }, 500);

		this.route.params.subscribe(params => {
			this.uploader_video.onBuildItemForm = (item, form) => {
				form.append("module_id", +params["course_id"]);
			};
		});
		this.uploader_video.onCompleteItem = (item:any, response:any, status:any, headers:any) => {
	    	response = JSON.parse(response);
	    	if (response.success) {
	    		alert("Thanks for uploading your video. It will be available in a few minutes after we complete video processing and compression.")
	    	} else {
	    		alert(response.message);
	    	}
			this.uploader_video.queue[0].remove();
			this.uploadProgress_video = 0;
	    };
	}

	ngOnDestroy(): void {
		clearInterval(this.refreshInterval);
		if (this.uploadInterval_video) clearInterval(this.uploadInterval_video);
	}

	private fake_points: Array<string> = [];
	private get_fake_points_for_membership(membership_index: number): any {
		while (this.fake_points.length < membership_index + 1) {
			let total = Math.floor(Math.random() * 100);
			let current = Math.floor(Math.random() * total);
			this.fake_points.push(current + " of " + total + " points");
		}
		return this.fake_points[membership_index];
	}

	private loadCourse(suppress_course_refresh: boolean): void {
		this.route.params.subscribe(params => {
			let course_id = +params["course_id"];
			if (course_id) {
				let user_id_for_hidden_items = (this.activeMembership == null) ? this.userService.getLoggedInUser().id : this.activeMembership.user_id;
				this.courseService.getCourseAsTeamMember(course_id, user_id_for_hidden_items).subscribe(
					res => {
						// Adjust show_availability so the UI doesn't flash:
						let team_old = this.team;
						this.team = res.team;
						if (team_old) {
							for (var i = 0; i < this.team.memberships.length; i ++) {
								for (var j = 0; j < team_old.memberships.length; j ++) {
									if (this.team.memberships[i].id == team_old.memberships[j].id) {
										(this.team.memberships[i] as any).show_availability = (team_old.memberships[j] as any).show_availability;
										break;
									}
								}
							}
						}

						if (!suppress_course_refresh) this.course = res.course;

						if ("user_id" in params) {
							this.evaluateTeam();
							for (var i = 0; i < this.team.memberships.length; i ++) {
								let membership = this.team.memberships[i];
								if (membership.user_id == +params["user_id"]) {
									this.activateMembership(membership, false, false);
									break;
								}
							}
						}

						for (var i = 0; i < this.team.memberships.length; i ++) {
							let membership = this.team.memberships[i];
							if (membership.user_id == this.userService.getLoggedInUser().id) {
								this.video = membership.video;
								break;
							}
						}
					}, 
					error => {
						// Do nothing.
					}
				);
			}
		});
	}

	private call(phone: string): void {
		window.location.href = "tel:" + phone;
	}

	public backBtnClicked(): void {
		//
	}

	private evaluateMyself(): void {
		this.activeMembership = null;
		this.isCourseDisplayed = true;
		this.isRatingHistoryDisplayed = false;
		this.is_leaderboard_displayed = false;

		// TODO: Hacky
		this.course = null;
		this.loadCourse(false);
	}

	private evaluateTeam(): void {
		this.isTeamDisplayed = true;
		this.isRatingHistoryDisplayed = false;
		this.is_leaderboard_displayed = false;
	}

	private activateMembership(membership: TeamMembership, refresh_course: boolean, show_user_video: boolean) {
		this.activeMembership = membership;
		this.show_user_video = show_user_video;
		this.isCourseDisplayed = true;
		this.isTeamDisplayed = false;

		// TODO: Hacky
		if (refresh_course) {
			this.course = null;
			this.loadCourse(false);
		}
	}

	private ignore_next_upload_click: boolean = false;
	private upload_video(): void {
		if (this.ignore_next_upload_click) {
			this.ignore_next_upload_click = false;
			return;
		}
		this.fileInput_video.nativeElement.click();
	}
	private view_video(): void {
		this.ignore_next_upload_click = true;
		window.open(AppSettings.USER_VIDEO_PATH + this.video + ".mp4");
	}
	private delete_video(): void {
		this.ignore_next_upload_click = true;
		if (confirm("Are you sure you wish to delete your video?")) {
			this.userService.delete_user_video(this.course.id).subscribe(
				res => {
					alert("Your video will be deleted shortly.");
				}, 
				error => {
					// Do nothing.
				}
			);
		}
	}

	private explorePerformance(): void {
		this.isCourseDisplayed = false;
		this.isRatingHistoryDisplayed = true;
		this.is_leaderboard_displayed = false;

		this.ratingHistory = null;
		this.ratingService.getRatingsForCourse(this.userService.getLoggedInUser().id, this.course.id).subscribe(
			ratings => {
				this.ratingHistory = ratings;
			}, 
			error => {
				// Do nothing.
			}
		);

		// TODO: Hacky
		this.course = null;
		this.loadCourse(false);
	}

	private show_leaderboard(): void {
		this.isTeamDisplayed = false;
		this.isCourseDisplayed = false;
		this.isRatingHistoryDisplayed = false;
		this.is_leaderboard_displayed = true;
	}

	private onExitedCourse(event: any): void {
		this.courseComponents.forEach(courseComponent => {
			if (courseComponent.resetCourse) courseComponent.resetCourse();
		});
		this.isCourseDisplayed = false;
	}

	private onRated(rating: Rating): void {
		rating.user_id = (this.activeMembership == null) ? this.userService.getLoggedInUser().id : this.activeMembership.user_id;
		rating.course_id = this.course.id;
		this.ratingService.saveRating(rating, null).subscribe(
			res => {
				// Do nothing.
			}, 
			error => {
				// Do nothing.
			}
		);
	}

	private onFinishedCourse(event: any): void {
		this.courseComponents.forEach(courseComponent => {
			if (courseComponent.resetCourse) courseComponent.resetCourse();
		});
		this.isCourseDisplayed = false;
		alert("Thanks! Your responses have been recorded.");
	}

}


