import { 
	Component, 
	EventEmitter, 
	Input, 
	OnInit, 
	Output, 
	QueryList, 
	ViewChildren
} from '@angular/core';

import { trigger, state, style, animate, transition } from '@angular/animations';

import { CourseService } from '../services/course.service';

import { SliderComponent } from '../slider/slider.component';

import { Course } from '../models/course';
import { Rating } from '../models/rating';

@Component({
	selector: 'course',
	templateUrl: './course.component.html',
	styleUrls: ['./course.component.css'], 
	animations: [
	    trigger('descriptionState', [
			state('visible', style({height: 'auto',marginBottom: '20px', lineHeight: '22px'})),
			state('void', style({height: '0px', marginBottom: '0px', lineHeight: '0px'})),
			transition('void => *', animate('500ms ease-out')), 
			transition('* => void', animate('500ms ease-in'))
	    ]), 
	    trigger('itemHiddenState', [
			state('void', style({
			    transform: 'translateX(-100%)'
			})),
			transition('void <=> *', animate(500)),
	    ]), 
	]
})
export class CourseComponent {

	@ViewChildren('slider') sliders: QueryList<SliderComponent>;

	@Input() course: Course;

	@Input() forceShowDescription = false;
	@Input() allowHide = true;

	@Output() exited = new EventEmitter<void>();
	@Output() finished = new EventEmitter<void>();
	@Output() rated = new EventEmitter<Rating>();

	constructor(
		private courseService: CourseService, 
	) {
		// 
	}

	ngOnInit(): void {
		//
	}

	private has_visible_items(): boolean {
		for (var i = 0; i < this.course.assessment_items.length; i ++) {
			if (!this.course.assessment_items[i].hidden) return true;
		}
		return false;
	}

	private onRated(rating: number, assessment_item_id: number): void {
		this.rated.emit(new Rating(null, null, null, rating, null, assessment_item_id, null));
	}

	public resetCourse(): void {
		this.resetSliders();
	}

	private resetSliders(): void {
		this.sliders.forEach(slider => {
			slider.value = 0;
		});
	}

	private exit(): void {
		if (confirm("Are you sure you want to exit this scorecard?")) this.exited.emit();
	}
	private finish(): void {
		if (confirm("Are you sure you are done with this scorecard?")) this.finished.emit();
	}

}


