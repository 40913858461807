import { 
	Component, 
	EventEmitter, 
	Input, 
	Output, 
} from '@angular/core';

import { trigger, state, style, animate, transition } from '@angular/animations';

import { AppSettings } from '../app-settings';

@Component({
	selector: 'slider-static',
	templateUrl: './slider-static.component.html',
	styleUrls: ['./slider-static.component.css'], 
})
export class StaticSliderComponent {

	// https://dev.customskills.com/report?key=M68H4YSJW63M3509WJ65

	private readonly APP_SETTINGS = AppSettings;

	@Input() facet: any;

	constructor(
		//
	) {
		//
	}

	private computeIndicatorPosition(): string {
		return "calc(" + Math.round(this.facet.slider_value * 100) + "% - 5px)";
	}

}


