import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettings } from '../app-settings';

import { Task } from '../models/task';

@Injectable()
export class TaskService {

	constructor(
		private httpClient: HttpClient, 
		private router: Router
	) { }

	private uid = 0;

	create_task_object(src: any): Task {
		if (src) {
			let r = new Task(
				src.id, 
				src.module_id, 
				src.user_id, 
				src.text, 
				this.uid++, 
				src.hasOwnProperty("is_complete") ? src.is_complete : false, 
			);
			return r;
		} else {
			return null;
		}
	}

	get_all_tasks(): Observable<any> {
		return this.httpClient.get(
			AppSettings.API_ENDPOINT + "get-all-tasks", 
			{withCredentials: true}
		).pipe(
			map(res => {
				if ((res as any).success) {
					(res as any).modules.forEach(module => {
						let tasks_old = module.tasks;
						module.tasks = new Array<Task>();
						tasks_old.forEach(task_old => {
							module.tasks.push(this.create_task_object(task_old));
						});
					});
					return (res as any).modules;
				} else {
					return null;
				}
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

	save_tasks(modules: Array<any>): Observable<any> {
		return this.httpClient.post(
			AppSettings.API_ENDPOINT + "save-tasks", 
			JSON.stringify({modules: modules}), 
			{headers: AppSettings.POST_HEADERS, withCredentials: true}
		).pipe(
			map(res => {
				return (res as any).modules;
			}),
			catchError(error => {
				alert("An error occurred.");
				return null;
			})
		);
	}

}


