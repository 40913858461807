import { 
	Component, 
	ElementRef, 
	Input, 
	OnInit, 
	ViewChild
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router'

import * as d3 from 'd3';

import { AppSettings } from '../app-settings';

import { AppComponentService } from '../services/appcomponent.service';
import { RatingService } from '../services/rating.service';
import { UserService } from '../services/user.service';

import { Rating } from '../models/rating';

@Component({
	selector: 'rating-history-graph',
	templateUrl: './rating-history-graph.component.html',
	styleUrls: ['./rating-history-graph.component.css']
})
export class RatingHistoryGraphComponent implements OnInit {

	@Input() user_id: number;
	@Input() assessment_item_id: number;
	@Input() ratings: Array<Rating>;

	@ViewChild('chart_container', {static: true}) chart_container: ElementRef;

	private APP_SETTINGS = AppSettings;

	constructor(
		private appComponentService: AppComponentService, 
		private ratingService: RatingService, 
		private route: ActivatedRoute, 
		private router: Router, 
		private userService: UserService
	) {
		// if (this.userService.getLoggedInUser() == null) {
		// 	this.router.navigate(["/"]);
		// 	return;
		// }
	}

	ngOnInit(): void {
		if (this.ratings) {
			this.processRatings();
		} else {
			this.route.params.subscribe(params => {
				let user_id = +params['user_id'];
				let assessment_item_id = +params['assessment_item_id'];
				this.ratingService.getRatingsForItem(user_id, assessment_item_id).subscribe(
					ratings => {
						this.ratings = ratings;
						this.processRatings();
					}, 
					error => {
						// Do nothing.
					}
				);
			});
		}
	}

	private processRatings(): void {
		for (var rating_index = 0; rating_index < this.ratings.length; rating_index ++) {
			let rating = this.ratings[rating_index];
			rating.x = rating.date;
			rating.y = rating.rating;
		}
		if (this.ratings.length > 0) this.generatePlot();
	}

	private generatePlot(): void {

		let PLOT_WIDTH = 400;
		// let PLOT_HEIGHT = PLOT_WIDTH * this.appComponentService.getAppComponent().getScreenHeight() / this.appComponentService.getAppComponent().getScreenWidth();
		let PLOT_HEIGHT = 200;
		let margin = ({top: 30, right: 30, bottom: 30, left: 30});

		const svg = d3.create("svg").attr("viewBox", [0, 0, PLOT_WIDTH, PLOT_HEIGHT]);

		let data = this.ratings;

		// X-axis
		let x_extent = d3.extent(data, d => d.x);
		let x = d3.scaleUtc()
			    .domain(x_extent).nice()
			    .range([margin.left, PLOT_WIDTH - margin.right]);
		svg.append("g").call(g => g
				.attr("transform", `translate(0,${PLOT_HEIGHT - margin.bottom})`)
			    // .call(d3.axisBottom(x).ticks(5))
			    .call(g => g.select(".domain").remove())
		);

		// Y-axis
		let y = d3.scaleLinear()
			    .domain([0, 10]).nice()
			    .range([PLOT_HEIGHT - margin.bottom, margin.top]);
		svg.append("g").call(g => g
				.attr("transform", `translate(${margin.left - 10},0)`)
			    .call(d3.axisLeft(y))
			    .call(g => g.select(".domain").remove())
		);

		// Average line:
		var ave = 0;
		for (var datum_index = 0; datum_index < data.length; datum_index ++) {
			ave += data[datum_index].y;
		}
		ave = ave / data.length;
		let scaled_ave = y(ave);
		svg.append("line")
				.attr("x1", x(x_extent[0]))
				.attr("y1", scaled_ave)
				.attr("x2", x(x_extent[1]))
				.attr("y2", scaled_ave)
				.attr("stroke", AppSettings.getColorForRating(ave));

		// Data points:
		svg.append("g")
				.attr("font-family", "sans-serif")
				.attr("font-size", 10)
				.selectAll("g")
				.data(data)
				.join("g")
				.attr("transform", d => `translate(${x(d.x) - 4},${y(d.y) - 4})`)
				.call(g => g.append("rect")
				.attr("fill", d => AppSettings.getColorForRating(d.rating))
				.attr("width", 8)
				.attr("height", 8)
				.attr("rx", 2));
				// .call(g => g.append("text")
				// .attr("fill", "#FFF")
				// .attr("dy", "0.35em")
				// .attr("x", 7)
				// .text(d => d.name));

		this.chart_container.nativeElement.append(svg.node());

	}

}


